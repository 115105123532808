import jwt_decode from "jwt-decode";
import moment, { Moment } from "moment";

export default class VendorToken {
    constructor(jwt?: string) {
        if (jwt) {
            let decoded: {
                Name: string;
                Email: string;
                Phone: string;
                Shipment_Id: number;
            } = jwt_decode(jwt);
            this.name = decoded.Name;
            this.email = decoded.Email;
            this.phone = decoded.Phone;
            this.shipment_id = decoded.Shipment_Id;
            this.loginTime = moment("0001-01-01");
            this.jwt = jwt;
        }
    }
    public name: string | null = null;
    public email: string | null = null;
    public phone: string | null = null;
    public shipment_id: number | null = null;
    public loginTime: Moment = moment("0001-01-01");
    public jwt: string | null = null;
}
