import { createSlice } from "@reduxjs/toolkit";
import ShipmentPo from "../../../models/ShipmentPo";
import { ActionWithPayload } from "../../actionTypes/Action";

const shipmentPos: ShipmentPo[] = [];
const ShipmentPoSlice = createSlice({
    name: "shipmentPos",
    initialState: shipmentPos,
    reducers: {
        shipmentPoSet(state, action: ActionWithPayload<ShipmentPo[]>) {
            return action.payload;
        },
        clearShipmentPoSet(state, action: ActionWithPayload<ShipmentPo[]>) {
            return [];
        },
    },
});

export const { shipmentPoSet, clearShipmentPoSet } = ShipmentPoSlice.actions;
export default ShipmentPoSlice.reducer;
