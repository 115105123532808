import { CLEAR_ALL } from "../../constants/reviewConstants/Review.Constants";
import { SET_ORIGINATION_ZIP_INFO, CLEAR_ORIGINATION_ZIP_INFO } from "../../constants/zipLookupConstants/ZipLookup.Constants";

const originationZipLookupReducer = (state = {}, action: any) => {
    switch (action.type) {
        case SET_ORIGINATION_ZIP_INFO:
            return action.payload;
        case CLEAR_ORIGINATION_ZIP_INFO:
            return {};
        case CLEAR_ALL:
            return {};
        default:
            return state;
    }
};

export default originationZipLookupReducer;