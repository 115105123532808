import { useSelector } from "react-redux";
import { ReduxState } from "../../../redux/reducers/_rootReducer/_ReduxState";
import WindowWidth from "../WindowWidth/WindowWidth";

function Header({ title }: { title: string }) {
    const width = WindowWidth();
    const pageElements = useSelector((state: ReduxState) => state.PageElementsReducer);
    return (
        <div
            className="App-header"
            style={{
                left: pageElements.isNavbarOpen && width > 1000 ? "calc(var(--app-nav-width)/2)" : 0,
            }}
        >
            {title}
        </div>
    );
}
export default Header;
