import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Shipment from "../../models/Shipment";
import { useSelector } from "react-redux";
import { ReduxState } from "../../redux/reducers/_rootReducer/_ReduxState";

export const usePageValidation = () => {
    const history = useNavigate();
    const shipmentReducer: Shipment = useSelector((store: ReduxState) => store.ShipmentReducer);

    useEffect(() => {
        if (shipmentReducer && shipmentReducer?.id === undefined) {
            history("/");
        }
    }, [shipmentReducer, history]);
};
