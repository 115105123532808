import axios from "axios";
import { put, takeEvery } from "redux-saga/effects";
import Carton from "../../../models/Carton";
import {
    ADD_SHIPMENT_CARTON,
    DELETE_ALL_SHIPMENT_CARTONS,
    DELETE_SHIPMENT_CARTON,
    FETCH_SHIPMENT_CARTONS,
    SET_SHIPMENT_CARTONS,
} from "../../constants/cartonConstants/carton.Constants";
import { IS_LOADING } from "../../constants/loadingStatusConstants/loadingStatus.constants";
import { ERROR_NOTIFICATION } from "../../constants/notificationConstants/notification.constants";
import { ResponseStatus } from "../_rootSaga/ResponseStatus";

function* addShipmentCarton(action: any) {
    yield put({ type: IS_LOADING, payload: true });
    try {
        let inCarton = action.payload;
        let shipmentId = action.payload.shipmentId;
        let request: string = `${process.env.REACT_APP_API_URL}/Shipment/${shipmentId}/Carton`;
        yield axios.post(request, inCarton);
        yield put({ type: FETCH_SHIPMENT_CARTONS, payload: shipmentId });
    } catch (error) {
        yield put({ type: ERROR_NOTIFICATION, payload: "Failed to add carton to shipment." });
    }
    yield put({ type: IS_LOADING, payload: false });
}

function* fetchAllCartonsByShipmentId(action: any) {
    yield put({ type: IS_LOADING, payload: true });
    try {
        let shipmentId: string = action.payload;
        let request: string = `${process.env.REACT_APP_API_URL}/Shipment/${shipmentId}/Carton`;
        const response: ResponseStatus<Carton[]> = yield axios.get(request);
        const shipmentCartons: Carton[] = response.data;
        yield put({ type: SET_SHIPMENT_CARTONS, payload: shipmentCartons });
    } catch (error) {
        yield put({ type: ERROR_NOTIFICATION, payload: "Failed to fetch carton by id." });
    }
    yield put({ type: IS_LOADING, payload: false });
}

function* deleteCartonByCartonId(action: any) {
    yield put({ type: IS_LOADING, payload: true });
    try {
        let cartonId: number = action.payload.cartonId;
        let shipmentId: number = action.payload.shipmentId;
        let request: string = `${process.env.REACT_APP_API_URL}/Shipment/${shipmentId}/Carton/${cartonId}`;
        yield axios.delete(request);
        yield put({ type: FETCH_SHIPMENT_CARTONS, payload: shipmentId });
    } catch (error) {
        yield put({ type: ERROR_NOTIFICATION, payload: "Failed to remove carton." });
    }
    yield put({ type: IS_LOADING, payload: false });
}

function* deleteAllShipmentCartons(action: any) {
    yield put({ type: IS_LOADING, payload: true });
    try {
        let shipmentId: number = action.payload;
        let request: string = `${process.env.REACT_APP_API_URL}/Shipment/${shipmentId}/Carton`;
        yield axios.delete(request);
        yield put({ type: FETCH_SHIPMENT_CARTONS, payload: shipmentId });
    } catch (error) {
        yield put({ type: ERROR_NOTIFICATION, payload: "Failed to delete shipment cartons." });
    }
    yield put({ type: IS_LOADING, payload: false });
}

function* cartonSaga() {
    yield takeEvery(ADD_SHIPMENT_CARTON, addShipmentCarton);
    yield takeEvery(FETCH_SHIPMENT_CARTONS, fetchAllCartonsByShipmentId);
    yield takeEvery(DELETE_SHIPMENT_CARTON, deleteCartonByCartonId);
    yield takeEvery(DELETE_ALL_SHIPMENT_CARTONS, deleteAllShipmentCartons);
}

export default cartonSaga;
