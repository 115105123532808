import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toggleLightDark } from "../../../redux/reducers/pageElementsReducer/PageElements.Reducer";
import { ReduxState } from "../../../redux/reducers/_rootReducer/_ReduxState";

function LightDark() {
    const dispatch = useDispatch();
    const pageElements = useSelector((state: ReduxState) => state.PageElementsReducer);
    return (
        <div
            className={`${pageElements.isDarkTheme ? "btn-toggle-lightdark-light" : "btn-toggle-lightdark-dark"}`}
            onClick={() => dispatch({ type: toggleLightDark })}
            style={{
                top: `calc(10px)`,
                left: `calc(100vw - 50px)`,
            }}
        >
            {pageElements.isDarkTheme ? <i className="k-icon k-icon-md k-i-globe-outline" /> : <i className="k-icon k-icon-md k-i-globe" />}
        </div>
    );
}
export default LightDark;
