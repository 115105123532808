import ShipmentInformationInput from "./ShipmentInformationInput";
import "../App.css";
import { Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { usePageValidation } from "../../components/customHooks/usePageValidation";

function ShipmentInformationPage() {
    const navigate = useNavigate();

    function GoBack() {
        navigate("/ShipmentAddressInfoPage");
    }

    usePageValidation();

    return (
        <>
            <div style={{ marginTop: "-10%" }}>
                <ShipmentInformationInput />
            </div>
            <div className="go-back-btn">
                <Button onClick={() => GoBack()}>Go Back</Button>
            </div>
        </>
    );
}

export default ShipmentInformationPage;
