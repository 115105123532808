import { useEffect, useState } from "react";

/*
	summary: This function tracks the width of the screen every time the size of the screen changes
	returns: number
*/
function WindowWidth(): number {
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return width;
}

export default WindowWidth;
