import { CLEAR_ALL } from "../../constants/reviewConstants/Review.Constants";
import {
    CLEAR_SHIPMENT_INFORMATION,
    SET_SHIPMENT_ALREADY_SUBMITTED,
    SET_SHIPMENT_INFORMATION,
} from "../../constants/shipmentInformationConstants/ShipmentInformation.Constants";
import { CLEAR_FOR_DUPLICATE, SHIPMENT_SUBMITTED } from "../../constants/shipmentConstants/Shipment.constants";

const shipmentInformationReducer = (state = { totalPallets: 0, shipmentSentSuccessfully: false, shipmentAlreadySubmitted: false }, action: any) => {
    switch (action.type) {
        case SET_SHIPMENT_INFORMATION:
            return { ...state, totalPallets: action.payload };
        case CLEAR_SHIPMENT_INFORMATION:
            return { totalPallets: 0, shipmentSentSuccessfully: false, shipmentAlreadySubmitted: false };
        case CLEAR_ALL:
            return { totalPallets: 0, shipmentSentSuccessfully: false, shipmentAlreadySubmitted: false };
        case CLEAR_FOR_DUPLICATE:
            return { totalPallets: 0, shipmentSentSuccessfully: false, shipmentAlreadySubmitted: false };
        case SHIPMENT_SUBMITTED:
            return { ...state, shipmentSentSuccessfully: action.payload };
        case SET_SHIPMENT_ALREADY_SUBMITTED:
            return { ...state, shipmentAlreadySubmitted: action.payload };
        default:
            return state;
    }
};

export default shipmentInformationReducer;
