import { IS_LOADING } from "../../constants/loadingStatusConstants/loadingStatus.constants";
import { CLEAR_ALL } from "../../constants/reviewConstants/Review.Constants";
import Action from "../_rootReducer/_Action";

const loadingReducer = (state = false, action: Action<boolean>) => {
	switch (action.type) {
		case IS_LOADING:
			return action.payload;
		case CLEAR_ALL:
			return false;
		default:
			return false;
	}
};

export default loadingReducer;