import OverrideType from "./OverrideType";
import ShipmentPo from "./ShipmentPo";

export default class Override {
  Id: number = 0;
  ShipmentPoId: number = 0;
  ShipmentPo?: ShipmentPo = undefined;
  OverrideTypeId: number = 0;
  OverrideType?: OverrideType = undefined;
  AssociateToApproveOverride: string = "";
  AssociateLocation: string = "";
}