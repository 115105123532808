import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Table } from "reactstrap";
import AS400ZipLookupResponse from "../../models/AS400ZipLookupResponse";
import DestinationAddress from "../../models/DestinationAddress";
import { SET_DESTINATION_ADDRESS } from "../../redux/constants/shipmentAddressConstants/ShipmentAddress.Constants";
import { CLEAR_DESTINATION_ZIP_INFO, VALIDATE_DESTINATION_ZIP } from "../../redux/constants/zipLookupConstants/ZipLookup.Constants";
import { ReduxState } from "../../redux/reducers/_rootReducer/_ReduxState";
import { iPageElements } from "../../redux/reducers/pageElementsReducer/PageElements.type";

export default function DestinationAddressInput() {
    const destinationAddress: DestinationAddress = useSelector((store: ReduxState) => store.DestinationAddressReducer);
    const zipCodeInfo: AS400ZipLookupResponse = useSelector((store: ReduxState) => store.DestinationZipLookupReducer);
    const pageElements: iPageElements = useSelector((store: ReduxState) => store.PageElementsReducer);

    const [destinationName, setDestinationName] = useState(destinationAddress.name || "");
    const [destinationStreetAddress1, setDestinationStreetAddress1] = useState(destinationAddress.address1 || "");
    const [destinationStreetAddress2, setDestinationStreetAddress2] = useState(destinationAddress.address2 || "");
    const [destinationCity, setDestinationCity] = useState(destinationAddress.city || "");
    const [destinationState, setDestinationState] = useState(destinationAddress.state || "");
    const [destinationZip, setDestinationZip] = useState(destinationAddress.zip || "");
    const [shouldCheckZip, setShouldCheckZip] = useState(false);

    const styleDarkMode = pageElements.isDarkTheme ? "k-text-light" : "";

    const dispatch = useDispatch();

    useEffect(() => {
        function zipLookup() {
            const isValid: boolean = /^\d+$/.test(destinationZip);
            if (isValid) {
                dispatch({ type: VALIDATE_DESTINATION_ZIP, payload: destinationZip });
            } else {
                setDestinationZip("");
                window.alert("Invalid zip code. Enter a valid zip code");
            }
        }

        if (shouldCheckZip && destinationZip.length === 5) {
            setShouldCheckZip(false);
            zipLookup();
        } else if (destinationZip.length < 5 && destinationZip.length > 0) {
            setShouldCheckZip(true);
        } else if (destinationZip.length === 0) {
            dispatch({ type: CLEAR_DESTINATION_ZIP_INFO });
            setShouldCheckZip(true);
            setDestinationCity("");
            setDestinationState("");
        }
    }, [dispatch, shouldCheckZip, destinationZip]);

    useEffect(() => {
        const validInputs =
            destinationName !== "" && destinationStreetAddress1 !== "" && destinationZip !== "" && destinationCity !== "" && destinationState !== "";

        if (validInputs)
            dispatch({
                type: SET_DESTINATION_ADDRESS,
                payload: {
                    name: destinationName,
                    address1: destinationStreetAddress1,
                    address2: destinationStreetAddress2,
                    city: destinationCity,
                    state: destinationState,
                    zip: destinationZip,
                },
            });
    }, [dispatch, destinationName, destinationStreetAddress1, destinationStreetAddress2, destinationCity, destinationState, destinationZip]);

    useEffect(() => {
        if (zipCodeInfo.success === "1") {
            setDestinationState(zipCodeInfo?.output?.state);
            setDestinationCity(zipCodeInfo?.output?.city);
        }
    }, [zipCodeInfo, destinationZip]);

    return (
        <div style={{ display: "flex", width: "100%" }}>
            <Table>
                <thead>
                    <tr>
                        <th>
                            <h2 className={styleDarkMode}>Destination Address</h2>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <Form>
                                <Input
                                    className="input-styling"
                                    maxLength={128}
                                    required={true}
                                    placeholder="Destination Name"
                                    value={destinationName}
                                    onChange={(e) => setDestinationName(e.target.value)}
                                    autoComplete="organization" // Use "organization" for a company or organization name
                                />
                                <Input
                                    className="input-styling"
                                    maxLength={128}
                                    required={true}
                                    placeholder="Destination Street Address 1"
                                    value={destinationStreetAddress1}
                                    onChange={(e) => setDestinationStreetAddress1(e.target.value)}
                                    autoComplete="street-address" // Use "street-address" for a street address
                                />
                                <Input
                                    className="input-styling"
                                    maxLength={128}
                                    placeholder="Destination Street Address 2 (optional)"
                                    value={destinationStreetAddress2}
                                    onChange={(e) => setDestinationStreetAddress2(e.target.value)}
                                    autoComplete="address-line2" // Use "address-line2" for a secondary address line
                                />
                                <Input
                                    style={{
                                        marginTop: "0.5em",
                                        width: "100%",
                                    }}
                                    maxLength={10}
                                    required={true}
                                    placeholder="Destination Zipcode"
                                    value={destinationZip}
                                    onChange={(e) => setDestinationZip(e.target.value)}
                                    autoComplete="postal-code" // Use "postal-code" for a postal code or zip code
                                />
                                <Input
                                    className="input-styling"
                                    maxLength={128}
                                    required={true}
                                    placeholder="Destination City"
                                    value={destinationCity}
                                    onChange={(e) => setDestinationCity(e.target.value)}
                                    autoComplete="address-level2" // Use "address-level2" for a city or locality
                                />
                                <Input
                                    className="input-styling"
                                    maxLength={2}
                                    required={true}
                                    placeholder="Destination State Abbreviation"
                                    value={destinationState}
                                    onChange={(e) => setDestinationState(e.target.value)}
                                    autoComplete="address-level1" // Use "address-level1" for a state or province abbreviation
                                />
                            </Form>

                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    );
}
