import { CLEAR_SHIPMENT_CARTONS, SET_SHIPMENT_CARTONS } from "../../constants/cartonConstants/carton.Constants";
import { CLEAR_ALL } from "../../constants/reviewConstants/Review.Constants";
import { CLEAR_FOR_DUPLICATE } from "../../constants/shipmentConstants/Shipment.constants";

const cartonReducer = (state = [], action: any) => {
    switch (action.type) {
        case SET_SHIPMENT_CARTONS:
            return action.payload;
        case CLEAR_SHIPMENT_CARTONS:
            return [];
        case CLEAR_ALL:
            return [];
        case CLEAR_FOR_DUPLICATE:
            return [];
        default:
            return state;
    }
};

export default cartonReducer;
