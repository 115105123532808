import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

export interface iNavbarLinkProps {
    title: string;
    link: string;
    children?: iNavbarLinkProps[];
    col?: number;
    disabled?: boolean;
    element?: any;
    isHidden?: boolean;
    isProtected?: boolean;
}

function NavbarLink({ title, link, col, disabled }: iNavbarLinkProps) {
    const location = useLocation();
    const navigate = useNavigate();
    const navigateToLink = (link: string) => {
        if (!disabled) {
            navigate(link);
        }
    };
    let buttonClass = "navlink ";
    if (location.pathname.endsWith(link)) {
        buttonClass += "active ";
    } else if (location.pathname.includes(link)) {
        buttonClass += "parent ";
    }
    if (disabled) {
        buttonClass += "disabled";
    }
    return (
        <div key={uuidv4()} className={`col-${col} d-flex mt-2 ${buttonClass}`} onClick={() => navigateToLink(link)}>
            {title}
        </div>
    );
}

export default NavbarLink;
