import { ReactNode } from "react";
import Content from "./Content/Content";
import Header from "./Header/Header";
import Navbar from "./Navbar/Navbar";
import NavbarToggleButton from "./Navbar/NavbarToggleButton";
import { pageTitle } from "./AppData";
import LightDark from "./LightDark/LightDark";

export default function AppWrapper({ element }: { element: ReactNode }) {
    return (
        <div>
            <Header title={pageTitle} />
            <Navbar />
            <LightDark />
            <NavbarToggleButton />
            <Content element={element} />
        </div>
    );
}
