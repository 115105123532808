import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { toggleNavbar } from "../../../redux/reducers/pageElementsReducer/PageElements.Reducer";
import { ReduxState } from "../../../redux/reducers/_rootReducer/_ReduxState";
import WindowWidth from "../WindowWidth/WindowWidth";

function NavbarToggleButton() {
    const dispatch = useDispatch();
    const width = WindowWidth();
    const pageElements = useSelector((state: ReduxState) => state.PageElementsReducer);
    return (
        <div
            className="btn btn-toggle-nav"
            data-tip
            data-for="set-navbar"
            onClick={() => dispatch({ type: toggleNavbar })}
            style={{
                left: pageElements.isNavbarOpen && width > 1000 ? "var(--app-nav-width)" : "0px",
            }}
        >
            <ReactTooltip id="set-navbar" place="right" effect="solid">
                {pageElements.isNavbarOpen ? "Hide Navigation" : "Show Navigation"}
            </ReactTooltip>
            <span style={{ top: -5 }} className={"k-icon k-i-menu"}></span>
        </div>
    );
}
export default NavbarToggleButton;
