import Action from "../../reducers/_rootReducer/_Action";
import { delay, put, takeEvery } from "redux-saga/effects";
import { notificationSet, removeNotificationByIndex } from "../../reducers/notificationReducer/notification.reducer";
import {
    ERROR_NOTIFICATION,
    SUCCESS_NOTIFICATION,
    INFO_NOTIFICATION,
    WARNING_NOTIFICATION,
} from "../../constants/notificationConstants/notification.constants";

function* errorNotification(action: Action<string>) {
    yield put({ type: notificationSet, payload: { type: "error", message: action.payload } });
}

function* successNotification(action: Action<string>) {
    yield put({ type: notificationSet, payload: { type: "success", message: action.payload } });
    yield delay(5000);
    yield put({ type: removeNotificationByIndex, payload: 0 });
}

function* infoNotification(action: Action<string>) {
    yield put({ type: notificationSet, payload: { type: "info", message: action.payload } });
    yield delay(10000);
    yield put({ type: removeNotificationByIndex, payload: 0 });
}

function* warningNotification(action: Action<string>) {
    yield put({ type: notificationSet, payload: { type: "warning", message: action.payload } });
    yield delay(10000);
    yield put({ type: removeNotificationByIndex, payload: 0 });
}

function* NotificationSaga() {
    yield takeEvery(ERROR_NOTIFICATION, errorNotification);
    yield takeEvery(SUCCESS_NOTIFICATION, successNotification);
    yield takeEvery(INFO_NOTIFICATION, infoNotification);
    yield takeEvery(WARNING_NOTIFICATION, warningNotification);
}

export default NotificationSaga;
