import axios from "axios";
import { put, takeEvery } from "redux-saga/effects";
import DestinationAddress from "../../../models/DestinationAddress";
import Store from "../../../models/Store";
import { IS_LOADING } from "../../constants/loadingStatusConstants/loadingStatus.constants";
import { ERROR_NOTIFICATION } from "../../constants/notificationConstants/notification.constants";
import { FETCH_PO_STORE, SET_PO_STORE } from "../../constants/poValidationConstants/PoValidation.Constants";
import { SET_DESTINATION_ADDRESS } from "../../constants/shipmentAddressConstants/ShipmentAddress.Constants";
import { ResponseStatus } from "../_rootSaga/ResponseStatus";

function* fetchDestinationStoreInfo(action: any) {
    yield put({ type: IS_LOADING, payload: true });
    try {
        let storeNumber: string = action.payload;
        const request: string = `${process.env.REACT_APP_API_URL}/store/${storeNumber}`;
        const response: ResponseStatus<Store> = yield axios.get(request);
        let store: Store = response.data;

        if (Number(store.storeNumber) === 44) {
            store.storeName = "IC Scheels Warehouse";
            store.storeAddressLine1 = "398 Westcor Drive";
            store.storeAddressLine2 = "";
            store.storeCity = "Coralville";
            store.storeState = "IA";
            store.storeZipCode = "52241";
        } else {
            store.storeName = `${store.storeNumber} - ${store.storeName}`;
        }

        yield put({ type: SET_PO_STORE, payload: store });

        const destinationAddress: DestinationAddress = {
            name: store.storeName,
            address1: store.storeAddressLine1,
            address2: store.storeAddressLine2,
            city: store.storeCity,
            state: store.storeState,
            zip: store.storeZipCode,
        };
        yield put({ type: SET_DESTINATION_ADDRESS, payload: destinationAddress });
    } catch (error) {
        yield put({ type: ERROR_NOTIFICATION, payload: "Failed to fetch destination store information" });
    }
    yield put({ type: IS_LOADING, payload: false });
}

function* storeSaga() {
    yield takeEvery(FETCH_PO_STORE, fetchDestinationStoreInfo);
}

export default storeSaga;
