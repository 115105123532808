export function isNullOrEmpty(inString: string): boolean {
  if (inString === '' || inString === undefined) {
    return true;
  } else {
    return false;
  }
}

export function validateEmail(inString: string) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isValidEmail = emailRegex.test(inString);
  if (isValidEmail) {
    return true
  } else {
    return false
  }
}

export function onlyNumbersRegex(inString: string) {
  const numberRegex = /^\d+$/;
  const isValid = numberRegex.test(inString);
  if (isValid) {
    return true
  } else {
    return false
  }
}