import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Input, Spinner, Table } from "reactstrap";
import { validateEmail } from "../../components/Utilities";
import AdditionalVendorContactInfo from "../../models/AdditionalVendorContactInfo";
import Shipment from "../../models/Shipment";
import {
    ADD_VENDOR_CONTACT,
    REMOVE_ALL_VENDOR_CONTACTS,
    REMOVE_VENDOR_CONTACT,
} from "../../redux/constants/additionalVendorContactInfoConstants/AdditionalVendorContactInfo.Constants";
import { ReduxState } from "../../redux/reducers/_rootReducer/_ReduxState";
import { iReviewInfo } from "../../models/IReviewInfo";
import { ToastContainer } from "react-toastify";
import { iPageElements } from "../../redux/reducers/pageElementsReducer/PageElements.type";
import { useNotifications } from "../../components/customHooks/useNotifications";
import "../App.css";
import { v4 as uuidv4 } from "uuid";

function AdditionalVendorContactInfoPage() {
    const shipment: Shipment = useSelector((store: ReduxState) => store.ShipmentReducer);
    const additionalVendorContacts: AdditionalVendorContactInfo[] = useSelector((store: ReduxState) => store.AdditionalVendorContactInfoReducer);
    const pageElements: iPageElements = useSelector((store: ReduxState) => store.PageElementsReducer);
    const loading: boolean = useSelector((store: ReduxState) => store.LoadingReducer);
    const reviewInfo: iReviewInfo = useSelector((store: ReduxState) => store.ReviewReducer);

    const [additionalVendorContactEmail, setAdditionalVendorContactEmail] = useState("");
    const [addingVendorContact, setAddingVendorContact] = useState(false);
    const formattedVendorPhoneNumber = `(${shipment?.primaryVendorContactPhone?.substring(0, 3)}) 
        ${shipment?.primaryVendorContactPhone?.substring(3, 6)}-${shipment?.primaryVendorContactPhone?.substring(6, 10)}`;

    const styleDarkMode = pageElements.isDarkTheme ? "k-text-light" : "";

    const navigate = useNavigate();
    const dispatch = useDispatch();

    function toggleInputVisibility() {
        setAdditionalVendorContactEmail("");
        setAddingVendorContact(!addingVendorContact);
    }

    async function addVendorContact() {
        if (!validateEmail(additionalVendorContactEmail)) {
            window.alert("Please enter a valid email address.");
            setAdditionalVendorContactEmail("");
        } else {
            toggleInputVisibility();
            dispatch({
                type: ADD_VENDOR_CONTACT,
                payload: {
                    shipmentId: shipment.id,
                    email: additionalVendorContactEmail,
                    id: 0,
                    shipment: null,
                },
            });
        }
    }

    function removeVendorContact(vendorContactId: number) {
        dispatch({
            type: REMOVE_VENDOR_CONTACT,
            payload: {
                contactId: vendorContactId,
                shipmentId: shipment.id,
            },
        });
    }

    function removeAllVendorContacts() {
        dispatch({
            type: REMOVE_ALL_VENDOR_CONTACTS,
            payload: { shipmentId: shipment.id },
        });
    }

    function nextPage() {
        if (reviewInfo.inReview) {
            navigate("/ShipmentReviewPage");
        } else {
            navigate("/PoValidationPage");
        }
    }

    function GoBack() {
        navigate("/VendorInfoPage");
    }

    useNotifications();

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme={pageElements.isDarkTheme ? "dark" : "light"}
            />
            {loading ? (
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        position: "absolute",
                        flexDirection: "column",
                        alignItems: "center",
                        top: "40%",
                    }}
                >
                    <div>Loading...</div>
                    <Spinner color="danger" type="grow">
                        {" "}
                    </Spinner>
                </div>
            ) : (
                <div className="col-12">
                    <div style={{ width: "50%" }}>
                        <Table>
                            <thead>
                                <tr>
                                    <th>
                                        <h2 className={styleDarkMode}>Primary Contact:</h2>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <h5 className={styleDarkMode + " contact_info contact_top"}>{shipment.primaryVendorContactName}</h5>
                                        <h5 className={styleDarkMode + " contact_info"}>{shipment.primaryVendorContactEmail}</h5>
                                        <h5 className={styleDarkMode + " contact_info"}>{formattedVendorPhoneNumber}</h5>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div>
                        <Button
                            size="small"
                            color="warning"
                            className="col-2"
                            style={{ marginTop: "1em" }}
                            disabled={addingVendorContact}
                            onClick={() => toggleInputVisibility()}
                        >
                            Add Contact
                        </Button>
                        <Button
                            size="small"
                            color="success"
                            className="col-2"
                            style={{
                                marginLeft: "1em",
                                marginTop: "1em",
                            }}
                            disabled={addingVendorContact}
                            onClick={() => nextPage()}
                        >
                            Continue
                        </Button>
                    </div>
                    {addingVendorContact ? (
                        <div>
                            <Input
                                style={{
                                    marginTop: "2em",
                                    width: "35%",
                                }}
                                placeholder="Email"
                                value={additionalVendorContactEmail}
                                onChange={(e) => setAdditionalVendorContactEmail(e.target.value)}
                            />
                            <Button onClick={() => toggleInputVisibility()} size="small" className="col-2" style={{ backgroundColor: "var(--app-primary)" }}>
                                Cancel
                            </Button>
                            <Button size="small" color="success" className="col-2" style={{ marginLeft: "1em" }} onClick={() => addVendorContact()}>
                                Submit
                            </Button>
                        </div>
                    ) : (
                        <></>
                    )}
                    <div>
                        {additionalVendorContacts.length > 0 ? (
                            <>
                                <div>
                                    <h2 style={{ marginTop: "0.5em" }}>
                                        Additional Contacts:
                                        {additionalVendorContacts.length > 1 ? (
                                            <Button
                                                size="small"
                                                style={{
                                                    backgroundColor: "#a12",
                                                    float: "right",
                                                }}
                                                disabled={addingVendorContact}
                                                onClick={() => removeAllVendorContacts()}
                                            >
                                                Remove All
                                            </Button>
                                        ) : (
                                            <></>
                                        )}
                                    </h2>
                                </div>
                                <Table>
                                    <tbody>
                                        {additionalVendorContacts?.map((vendorContact) => (
                                            <tr key={uuidv4()}>
                                                <td className={styleDarkMode}>{vendorContact.email}</td>
                                                <td>
                                                    <Button
                                                        size="small"
                                                        style={{
                                                            backgroundColor: "#a12",
                                                            float: "right",
                                                        }}
                                                        disabled={addingVendorContact}
                                                        onClick={() => removeVendorContact(vendorContact.id)}
                                                    >
                                                        Remove
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            )}
            <div className="go-back-btn">
                <Button onClick={() => GoBack()}>Go Back</Button>
            </div>
        </>
    );
}

export default AdditionalVendorContactInfoPage;
