import { useSelector } from "react-redux";
import AppWrapper from "../components/pageElements/AppWrapper";
import ProtectedRoutes from "../components/protectedRoutes/ProtectedRoutes";
import { ReduxState } from "../redux/reducers/_rootReducer/_ReduxState";
import "./App.css";

function App() {
    const pageElements = useSelector((state: ReduxState) => state.PageElementsReducer);

    return (
        <div className={pageElements.isDarkTheme ? "App-dark" : "App-light"}>
            <AppWrapper element={<ProtectedRoutes />} />
        </div>
    );
}

export default App;
