import { Fragment, ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../../redux/reducers/_rootReducer/_ReduxState";
import NavbarLink, { iNavbarLinkProps } from "./NavbarLink";
import { navigationLinks } from "../AppData";
import { Location, useLocation } from "react-router-dom";
import { toggleNavbar } from "../../../redux/reducers/pageElementsReducer/PageElements.Reducer";
import User from "../../../models/User";
import WindowWidth from "../WindowWidth/WindowWidth";
import { v4 as uuidv4 } from "uuid";

const GenerateNavLinks = (
    user: User,
    location: Location,
    links: iNavbarLinkProps[],
    iterationsDone: number,
    parentLink: string,
    navWidth: string
): ReactNode => {
    return (
        <Fragment>
            {links.map((item: iNavbarLinkProps) => {
                return !item.isHidden ? (
                    <div className="row d-flex justify-content-end p-1" style={{ width: navWidth }} key={uuidv4()}>
                        <NavbarLink key={uuidv4()} link={parentLink + "/" + item.link} title={item.title} col={12 - iterationsDone} disabled={item.disabled} />
                        {location.pathname.includes(item.link)
                            ? GenerateNavLinks(user, location, item.children!, iterationsDone + 1, parentLink + "/" + item.link, navWidth)
                            : null}
                    </div>
                ) : null;
            })}
        </Fragment>
    );
};

/*
    summary: This function determines whether the navbar should be open or not, then displays the navbar if so
    returns: Element
*/
function Navbar() {
    const location = useLocation();
    const dispatch = useDispatch();
    const pageElements = useSelector((state: ReduxState) => state.PageElementsReducer);
    const user = useSelector((state: ReduxState) => state.UserReducer);
    const size = WindowWidth();

    useEffect(() => {
        const fullScreen = false;
        dispatch({ type: toggleNavbar, payload: fullScreen });
    }, [dispatch, size]);

    const navLinks = navigationLinks;
    return (
        <div
            className="App-nav row d-flex justify-content-center"
            style={{
                left: pageElements.isNavbarOpen || size < 1000 ? "0px" : "calc(-1 * var(--app-nav-width))",
                width: size > 1000 ? "var(--app-nav-width)" : "100vw",
                height: size > 1000 ? "100vh" : "unset",
                top: size > 1000 || pageElements.isNavbarOpen ? "0" : "-100vh",
            }}
        >
            <img
                style={{
                    borderRadius: "0%",
                    top: size > 1000 || pageElements.isNavbarOpen ? "20px" : "calc(-1 * 100vh)",
                    transition: "1s",
                    width: "var(--app-nav-width)",
                    height: "70px",
                    display: "flex",
                    position: "fixed",
                    marginBottom: "100px",
                }}
                src={require("../pictures/Logo.png")}
                alt="Scheels Logo"
            />
            <div className="col-12 mt-5">{GenerateNavLinks(user, location, navLinks, 0, "", size > 1000 ? "var(--app-nav-width)" : "100vw")}</div>
        </div>
    );
}

export default Navbar;
