import { createSlice } from "@reduxjs/toolkit";
import { ActionWithPayload } from "../../actionTypes/Action";
import AdditionalVendorContactInfo from "../../../models/AdditionalVendorContactInfo";

const additionalVendorContacts: AdditionalVendorContactInfo[] = [];
const AdditionalVendorConactInfoSlice = createSlice({
    name: "additionalVendorContactInfo",
    initialState: additionalVendorContacts,
    reducers: {
        additionalVendorContactInfoSet(state, action: ActionWithPayload<AdditionalVendorContactInfo[]>) {
            state = action.payload;
            return state;
        },
        clearAdditionalVendorContactInfoSet(state, action: ActionWithPayload<AdditionalVendorContactInfo[]>) {
            state = [];
            return state;
        },
    },
});

export const { additionalVendorContactInfoSet, clearAdditionalVendorContactInfoSet } = AdditionalVendorConactInfoSlice.actions;
export default AdditionalVendorConactInfoSlice.reducer;