import Action from "../../reducers/_rootReducer/_Action";

//this is still necessary to export because our saga reads from it.
export const VALIDATE_USER = "VALIDATE_USER";
export const FETCH_USERS = "FETCH_USERS";

//this is the content we are adding
export interface iValidateLdapPayload {
    username?: string;
    password?: string;
}
export function ValidateLdapUser(username: string, password: string) {
    const returnVal: Action<iValidateLdapPayload> = {
        type: VALIDATE_USER,
        payload: { username, password },
    };
    return returnVal;
}
export const VALIDATE_GOOGLE_USER = "VALIDATE_GOOGLE_USER";

//this is the content we are adding
export interface iValidateGooglePayload {
    jwt: string;
}
export function ValidateGoogleUser(jwt: string) {
    const returnVal: Action<iValidateGooglePayload> = {
        type: VALIDATE_GOOGLE_USER,
        payload: { jwt },
    };
    return returnVal;
}
