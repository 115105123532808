import { createSlice } from "@reduxjs/toolkit";

const pageElementsSlice = createSlice({
    name: "user",
    initialState: { isDarkTheme: false, isNavbarOpen: true },
    reducers: {
        toggleNavbar(state, action) {
            if (typeof action.payload == typeof undefined) {
                state.isNavbarOpen = !state.isNavbarOpen;
            } else {
                state.isNavbarOpen = action.payload;
            }
        },
        toggleLightDark(state) {
            state.isDarkTheme = !state.isDarkTheme;
        },
    },
});

export const { toggleNavbar, toggleLightDark } = pageElementsSlice.actions;
export default pageElementsSlice.reducer;
