import axios from "axios";
import { put, takeEvery } from "redux-saga/effects";
import AS400ZipLookupResponse from "../../../models/AS400ZipLookupResponse";
import { IS_LOADING } from "../../constants/loadingStatusConstants/loadingStatus.constants";
import { ERROR_NOTIFICATION } from "../../constants/notificationConstants/notification.constants";
import { SET_DESTINATION_ZIP_INFO, VALIDATE_DESTINATION_ZIP } from "../../constants/zipLookupConstants/ZipLookup.Constants";
import { ResponseStatus } from "../_rootSaga/ResponseStatus";

function* validateZip(action: any) {
    yield put({ type: IS_LOADING, payload: true });
    try {
        let zipCode: string = action.payload
        let request: string = `${process.env.REACT_APP_API_URL}/ZipLookup/${zipCode}`;
        const response: ResponseStatus<AS400ZipLookupResponse> = yield axios.get(request);
        const zipInfo: AS400ZipLookupResponse = response.data;
        yield put({ type: SET_DESTINATION_ZIP_INFO, payload: zipInfo });
    } catch (error) {
        yield put({ type: ERROR_NOTIFICATION, payload: "Failed to validate zipcode." });
    }
    yield put({ type: IS_LOADING, payload: false });
}

function* destinationZipLookupSaga() {
    yield takeEvery(VALIDATE_DESTINATION_ZIP, validateZip);
}

export default destinationZipLookupSaga;