import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, ButtonGroup, Form, Input, Spinner, Table } from "reactstrap";
import Pallet from "../../models/Pallet";
import { ADD_SHIPMENT_PALLET } from "../../redux/constants/palletConstants/pallet.Constants";
import { ReduxState } from "../../redux/reducers/_rootReducer/_ReduxState";
import ShipmentPalletSnapshot from "./ShipmentPalletSnapshot";
import Shipment from "../../models/Shipment";
import iShipmentInformation from "../../models/IShipmentInformation";
import { iPageElements } from "../../redux/reducers/pageElementsReducer/PageElements.type";

function ShipmentPalletDetailsInput() {
    const shipmentPallets: Pallet[] = useSelector((store: ReduxState) => store.PalletReducer);
    const shipment: Shipment = useSelector((store: ReduxState) => store.ShipmentReducer);
    const shipmentInfo: iShipmentInformation = useSelector((store: ReduxState) => store.ShipmentInformationReducer);
    const loading: boolean = useSelector((store: ReduxState) => store.LoadingReducer);
    const pageElements: iPageElements = useSelector((store: ReduxState) => store.PageElementsReducer);

    const [description, setDescription] = useState("");
    const [isStackable, setIsStackable] = useState(false);
    const [inputCubes, setInputCubes] = useState(false);
    const [length, setLength] = useState("");
    const [width, setWidth] = useState("");
    const [height, setHeight] = useState("");
    const [weight, setWeight] = useState("");
    const [cubicFeet, setCubicFeet] = useState("");
    const [nmfcCode, setNmfcCode] = useState("");
    const [quantity, setQuantity] = useState(1);

    const dispatch = useDispatch();

    const tooManyPallets: boolean = quantity > shipmentInfo.totalPallets - shipmentPallets.length;
    const styleDarkMode = pageElements.isDarkTheme ? "k-text-light" : "";

    let newPallet = new Pallet();

    function savePalletDetails() {
        if (!tooManyPallets) {
            newPallet = {
                id: 0,
                shipmentId: shipment.id,
                isStackable: isStackable,
                length: parseInt(length) || 1,
                width: parseInt(width) || 1,
                height: parseInt(height) || 1,
                weight: parseInt(weight),
                description: description,
                cubicFeet: parseInt(cubicFeet),
                nmfcCode: nmfcCode,
                palletFreightClassification: undefined,
                quantity: quantity,
            };

            dispatch({
                type: ADD_SHIPMENT_PALLET,
                payload: {
                    newPallet: newPallet,
                    shipmentId: shipment.id,
                },
            });
            clearInputs();
        } else {
            window.alert("Too many pallets. Check your quantities.");
            setQuantity(shipmentInfo.totalPallets - shipmentPallets.length);
        }
    }

    function clearInputs() {
        setDescription("");
        setIsStackable(false);
        setLength("");
        setWidth("");
        setHeight("");
        setWeight("");
        setCubicFeet("");
        setNmfcCode("");
        setQuantity(1);
        newPallet = new Pallet();
    }

    useEffect(() => {
        if (!inputCubes && length !== "" && width !== "" && height !== "") {
            let cubes: number = (parseInt(length) * parseInt(width) * parseInt(height)) / 1728;
            setCubicFeet(cubes.toString());
        }
    }, [length, width, height, inputCubes]);

    useEffect(() => {
        if (inputCubes && cubicFeet !== "") {
            setLength("1");
            setWidth("1");
            setHeight("1");
        }
    }, [cubicFeet, inputCubes]);

    function disableBtn(): boolean {
        if (shipmentPallets.length === Number(shipmentInfo.totalPallets)) {
            return true;
        } else return (!inputCubes && (length === "" || width === "" || height === "" || weight === "")) || (inputCubes && (cubicFeet === "" || weight === ""));
    }

    function toggleInput() {
        clearInputs();
        setInputCubes(!inputCubes);
    }

    return (
        <>
            {loading ? (
                <div
                    style={{
                        width: "60%",
                        display: "flex",
                        position: "absolute",
                        flexDirection: "column",
                        alignItems: "center",
                        top: "40%",
                    }}
                >
                    <div>Loading...</div>
                    <Spinner color="danger" type="grow">
                        {" "}
                    </Spinner>
                </div>
            ) : (
                <div style={{ display: "flex", width: "100%" }}>
                    <div style={{ width: "50%" }}>
                        <Table style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th>
                                        <h2 className={styleDarkMode + " header"}>Pallet Information</h2>
                                    </th>
                                    <th>
                                        <Button className="button" color="info" onClick={() => toggleInput()}>
                                            {inputCubes ? <>Input Pallet Dimensions</> : <>Input Pallet Cubes</>}
                                        </Button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan={2}>
                                        <Form>
                                            {!inputCubes ? (
                                                <>
                                                    <Input
                                                        required={true}
                                                        maxLength={4}
                                                        type="number"
                                                        placeholder="Length (in)"
                                                        value={length}
                                                        onChange={(e) => setLength(e.target.value)}
                                                    />
                                                    <Input
                                                        required={true}
                                                        maxLength={4}
                                                        type="number"
                                                        placeholder="Width (in)"
                                                        value={width}
                                                        onChange={(e) => setWidth(e.target.value)}
                                                    />
                                                    <Input
                                                        required={true}
                                                        maxLength={4}
                                                        type="number"
                                                        placeholder="Height (in)"
                                                        value={height}
                                                        onChange={(e) => setHeight(e.target.value)}
                                                    />
                                                </>
                                            ) : (
                                                <Input
                                                    required={true}
                                                    maxLength={4}
                                                    type="number"
                                                    placeholder="Cubes"
                                                    value={cubicFeet}
                                                    onChange={(e) => setCubicFeet(e.target.value)}
                                                />
                                            )}
                                            <Input
                                                required={true}
                                                maxLength={5}
                                                type="number"
                                                placeholder="Weight (lbs)"
                                                value={weight}
                                                onChange={(e) => setWeight(e.target.value)}
                                            />
                                            <Input
                                                required={true}
                                                maxLength={1000}
                                                placeholder="Description of product on pallet"
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                            />
                                            <Input maxLength={6} placeholder="NMFC Code" value={nmfcCode} onChange={(e) => setNmfcCode(e.target.value)} />
                                            <div style={{ width: "100%" }}>
                                                <div>
                                                    <div>
                                                        <h5 className={styleDarkMode}>Cubic Footage:</h5>
                                                    </div>
                                                    <div>
                                                        <h3
                                                            style={{
                                                                marginLeft: "0.75em",
                                                                color: "#a12",
                                                            }}
                                                        >
                                                            {Number(cubicFeet).toFixed(2)}
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ width: "100%" }}>
                                                <>
                                                    <div>
                                                        <h5 className={styleDarkMode + " input-styling"}>Stackable?</h5>
                                                    </div>
                                                    <div>
                                                        <ButtonGroup style={{ marginLeft: "0.5em" }}>
                                                            <Button color="primary" outline onClick={() => setIsStackable(false)} active={!isStackable}>
                                                                No
                                                            </Button>
                                                            <Button color="primary" outline onClick={() => setIsStackable(true)} active={isStackable}>
                                                                Yes
                                                            </Button>
                                                        </ButtonGroup>
                                                    </div>
                                                </>
                                            </div>
                                            <div style={{ width: "100%" }}>
                                                <>
                                                    <div>
                                                        <h5 className={styleDarkMode + " input-styling"}>Number of pallets with these details?</h5>
                                                    </div>
                                                    <div>
                                                        <Input
                                                            style={{
                                                                marginLeft: "0.75em",
                                                                width: "18%",
                                                            }}
                                                            type="number"
                                                            required={true}
                                                            maxLength={2}
                                                            value={quantity}
                                                            onChange={(e) => setQuantity(Number(e.target.value))}
                                                        />
                                                    </div>
                                                </>
                                            </div>
                                        </Form>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>
                                        <Button
                                            style={{
                                                backgroundColor: "#a12",
                                                marginLeft: "0.75em",
                                            }}
                                            onClick={() => clearInputs()}
                                        >
                                            Clear
                                        </Button>
                                        <Button color="success" disabled={disableBtn()} onClick={() => savePalletDetails()}>
                                            Save
                                        </Button>
                                    </td>
                                </tr>
                            </tfoot>
                        </Table>
                    </div>
                    <div style={{ width: "50%" }}>
                        <ShipmentPalletSnapshot />
                    </div>
                </div>
            )}
        </>
    );
}

export default ShipmentPalletDetailsInput;
