import { createSlice } from "@reduxjs/toolkit";
import Action from "../_rootReducer/_Action";
import iNotification from "./notification.type";

const initialState: iNotification[] = [];
const NotificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        notificationSet(state, action: Action<iNotification>) {
            state.push(action.payload);
            return state;
        },
        removeNotificationByIndex(state, action) {
            state.splice(action.payload, 1);
            return state;
        },
        removeLastNotification(state) {
            return state.slice(0, -1);
        },
    },
});

export const { notificationSet, removeNotificationByIndex, removeLastNotification } = NotificationSlice.actions;
export default NotificationSlice.reducer;
