import { Route, Routes } from "react-router";
import { navigationLinks } from "../pageElements/AppData";
import { iNavbarLinkProps } from "../pageElements/Navbar/NavbarLink";
import Protected from "./Protected";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

let pageElements = (parentElements: iNavbarLinkProps[]) => {
    return parentElements.map((item: iNavbarLinkProps, key: number) => {
        if (item.isProtected) {
            return (
                <Route path={item.link} element={<Protected Component={<item.element />} />} key={uuidv4()}>
                    {item.children!.length > 0 && pageElements(item.children!)}
                </Route>
            );
        } else {
            return (
                <Route path={item.link} element={<item.element />} key={uuidv4()}>
                    {item.children!.length > 0 && pageElements(item.children!)}
                </Route>
            );
        }
    });
};

function IgnoreManagementUrl() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname !== "/management") {
            navigate("/");
        }
    }, [location, navigate]);

    return null;
}

function ProtectedRoutes() {
    return (
        <Routes>
            {pageElements(navigationLinks)}
            <Route path="/*" element={<IgnoreManagementUrl />}></Route>
        </Routes>
    );
}

export default ProtectedRoutes;
