import { CLEAR_ALL } from "../../constants/reviewConstants/Review.Constants";
import { CLEAR_FOR_DUPLICATE, CLEAR_SHIPMENT, SET_SHIPMENT } from "../../constants/shipmentConstants/Shipment.constants";

const shipmentReducer = (state = { id: null }, action: any) => {
    switch (action.type) {
        case SET_SHIPMENT:
            return action.payload;
        case CLEAR_SHIPMENT:
            return {};
        case CLEAR_ALL:
            return {};
        case CLEAR_FOR_DUPLICATE:
            return {};
        default:
            return state;
    }
};

export default shipmentReducer;
