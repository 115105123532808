import "../ShipmentInformationDetailsPage/ShipmentInformationDetails.css";
import ShipmentPalletDetailsInput from "./ShipmentPalletDetailsInput";

function ShipmentInformationDetailsInput() {
    return (
        <div>
            <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "100%" }}>
                    <ShipmentPalletDetailsInput />
                </div>
            </div>
        </div>
    );
}

export default ShipmentInformationDetailsInput;