import * as React from "react";
import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { AuthenticationStatus } from "../../redux/constants/loadingStatusConstants/loadingStatus.constants";

import { ValidateLdapUser } from "../../redux/constants/userConstants/User.constants";
import { ReduxState } from "../../redux/reducers/_rootReducer/_ReduxState";
import { Loader } from "@progress/kendo-react-indicators";

import GoogleLogin from "./GoogleLogin";
import { Card, CardBody, Row, Col, Alert, Button } from "reactstrap";
function Login() {
    const loginStatus: AuthenticationStatus = useSelector((store: ReduxState) => store.LoginReducer);
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();

    async function authenticateUser() {
        await dispatch(ValidateLdapUser(userName, password));
    }

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === "Enter") {
            authenticateUser();
        }
    };

    return (
        <Card className="cred-box col-12 col-xl-6 " color="danger">
            <CardBody className="card-text row d-flex justify-content-around">
                <Col xs={10} className="text-center">
                    <h2>Sign in</h2>
                </Col>
                <Col xs={10} md={5}>
                    <h3>Username:</h3>
                </Col>
                <Col xs={10} md={5} className="text-md-right">
                    <input
                        id="userNameInput"
                        autoFocus
                        className="input-field col-10"
                        type="text"
                        placeholder="UserName"
                        onKeyPress={(e) => handleKeyPress(e)}
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                    />
                </Col>
                <Col xs={10} md={5}>
                    <h3>Password:</h3>
                </Col>
                <Col xs={10} md={5} className="text-md-right">
                    <input
                        id="passwordInput"
                        className="input-field col-10"
                        type="password"
                        placeholder="Password"
                        onKeyPress={(e) => handleKeyPress(e)}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Col>
                <Col xs={10} className="text-md-right">
                    {loginStatus === AuthenticationStatus.Failed ? (
                        <Row>
                            <Col>
                                <Alert color="danger">Invalid Username/Password</Alert>
                            </Col>
                        </Row>
                    ) : (
                        <></>
                    )}
                </Col>

                <Col xs={10} className="text-center">
                    <Button className="page-btn col-10" color="danger" size="sm" onClick={() => authenticateUser()}>
                        Log In
                    </Button>
                </Col>
                <Col xs={10} className="text-center">
                    {loginStatus === AuthenticationStatus.Processing && <Loader size="large" type={"infinite-spinner"} />}
                </Col>
                <GoogleLogin />
            </CardBody>
        </Card>
    );
}

export default Login;
