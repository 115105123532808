import axios from "axios";
import { put, takeEvery } from "redux-saga/effects";
import Shipment from "../../../models/Shipment";
import VendorContactInfo from "../../../models/VendorContactInfo";
import VendorToken from "../../../models/VendorToken";
import { IS_LOADING } from "../../constants/loadingStatusConstants/loadingStatus.constants";
import { ERROR_NOTIFICATION } from "../../constants/notificationConstants/notification.constants";
import {
    CLEAR_FOR_DUPLICATE,
    CREATE_SHIPMENT,
    DUPLICATE_SHIPMENT,
    FETCH_SELECTED_SHIPMENT,
    FETCH_SELECTED_SHIPMENT_WITH_DETAILS,
    SET_SHIPMENT,
    SHIPMENT_SUBMITTED,
    SUBMIT_SHIPMENT,
    UPDATE_SHIPMENT,
} from "../../constants/shipmentConstants/Shipment.constants";
import { ResponseStatus } from "../_rootSaga/ResponseStatus";
import Cookies from "js-cookie";
import { SET_DUPLICATE } from "../../constants/reviewConstants/Review.Constants";
import { clearShipmentPoSet } from "../../reducers/shipmentPoReducer/ShipmentPo.Reducer";
import { SET_SHIPMENT_ALREADY_SUBMITTED } from "../../constants/shipmentInformationConstants/ShipmentInformation.Constants";

function* createShipment(action: any) {
    yield put({ type: IS_LOADING, payload: true });
    try {
        const newContactInfo: VendorContactInfo = action.payload;
        let request: string = `${process.env.REACT_APP_API_URL}/Shipment`;
        const response: ResponseStatus<any> = yield axios.post(request, newContactInfo);
        const vendorToken: VendorToken = new VendorToken(response.data);
        if (vendorToken.jwt !== null) {
            Cookies.set("jwt", vendorToken.jwt, { sameSite: "Strict" });
            yield put({ type: FETCH_SELECTED_SHIPMENT, payload: vendorToken.shipment_id });
        } else {
            yield put({ type: ERROR_NOTIFICATION, payload: "Failed to validate JWT." });
        }
    } catch (error) {
        yield put({ type: ERROR_NOTIFICATION, payload: "Failed create shipment." });
    }
    yield put({ type: IS_LOADING, payload: false });
}

function* fetchShipmentById(action: any) {
    yield put({ type: IS_LOADING, payload: true });
    try {
        let shipmentId: string = action.payload;
        let request: string = `${process.env.REACT_APP_API_URL}/Shipment/${shipmentId}?includeFullShipmentInfo=false`;
        const response: ResponseStatus<Shipment> = yield axios.get(request);
        const shipment: Shipment = response.data;
        yield put({ type: SET_SHIPMENT, payload: shipment });
    } catch (error) {
        yield put({ type: ERROR_NOTIFICATION, payload: `Failed to fetch shipment with id: ${action.payload}` });
    }
    yield put({ type: IS_LOADING, payload: false });
}

function* fetchShipmentWithDetails(action: any) {
    yield put({ type: IS_LOADING, payload: true });
    try {
        let shipmentId: string = action.payload;
        let request: string = `${process.env.REACT_APP_API_URL}/Shipment/${shipmentId}?includeFullShipmentInfo=true`;
        const response: ResponseStatus<Shipment> = yield axios.get(request);
        const shipment: Shipment = response.data;
        yield put({ type: SET_SHIPMENT, payload: shipment });
    } catch (error) {
        yield put({
            type: ERROR_NOTIFICATION,
            payload: `Failed fetch shipment with details. Shipment id: ${action.payload}`,
        });
    }
    yield put({ type: IS_LOADING, payload: false });
}

function* updateShipment(action: any) {
    yield put({ type: IS_LOADING, payload: true });
    try {
        const shipmentToUpdate: Shipment = action.payload;
        let request: string = `${process.env.REACT_APP_API_URL}/Shipment/${shipmentToUpdate.id}`;
        const response: ResponseStatus<any> = yield axios.put(request, shipmentToUpdate);
        const vendorToken: VendorToken = new VendorToken(response.data);
        if (vendorToken.jwt != null) Cookies.set("jwt", vendorToken.jwt, { sameSite: "Strict" });
        // yield put({ type: SET_VENDOR_TOKEN, payload: vendorToken });
        yield put({ type: FETCH_SELECTED_SHIPMENT, payload: shipmentToUpdate.id });
    } catch (error) {
        yield put({ type: ERROR_NOTIFICATION, payload: "Failed update shipment." });
    }
    yield put({ type: IS_LOADING, payload: false });
}

function* submitShipment(action: any) {
    yield put({ type: IS_LOADING, payload: true });
    try {
        const finalShipment: Shipment = action.payload.finalShipment;
        let request: string = `${process.env.REACT_APP_API_URL}/Shipment/${finalShipment.id}`;
        const response: ResponseStatus<any> = yield axios.put(request, finalShipment);
        if (response.status >= 200 && response.status < 300) {
            //for notification
            yield put({ type: SHIPMENT_SUBMITTED, payload: true });
            //for disabling edit/submit buttons
            yield put({ type: SET_SHIPMENT_ALREADY_SUBMITTED, payload: true });
        } else {
            yield put({
                type: ERROR_NOTIFICATION,
                payload: `Failed to submit shipment. If issue persists please send screenshot of this page to Logistics@scheels.com`,
            });
        }
    } catch (error: any) {
        yield put({
            type: ERROR_NOTIFICATION,
            payload: `Failed to submit shipment. ${error.response.data} If issue persists please send screenshot of this page to Logistics@scheels.com`,
        });
    }
    yield put({ type: IS_LOADING, payload: false });
}

function* duplicateShipment(action: any) {
    yield put({ type: IS_LOADING, payload: true });
    try {
        const shipmentIdToDuplicate: Shipment = action.payload;
        const request: string = `${process.env.REACT_APP_API_URL}/Shipment/Duplicate/${shipmentIdToDuplicate}`;
        const response: ResponseStatus<any> = yield axios.post(request);
        const newVendorToken: VendorToken = new VendorToken(response.data);
        yield put({ type: SET_DUPLICATE, payload: true });
        // gonna need some changes here to get the data we want
        yield put({ type: CLEAR_FOR_DUPLICATE });
        yield put({ type: clearShipmentPoSet });
        yield put({ type: FETCH_SELECTED_SHIPMENT, payload: newVendorToken.shipment_id });
    } catch (error) {
        yield put({ type: ERROR_NOTIFICATION, payload: "Failed to duplicate shipment." });
    }
    yield put({ type: IS_LOADING, payload: false });
}

function* shipmentSaga() {
    yield takeEvery(CREATE_SHIPMENT, createShipment);
    yield takeEvery(FETCH_SELECTED_SHIPMENT, fetchShipmentById);
    yield takeEvery(FETCH_SELECTED_SHIPMENT_WITH_DETAILS, fetchShipmentWithDetails);
    yield takeEvery(UPDATE_SHIPMENT, updateShipment);
    yield takeEvery(SUBMIT_SHIPMENT, submitShipment);
    yield takeEvery(DUPLICATE_SHIPMENT, duplicateShipment);
}

export default shipmentSaga;
