import { CLEAR_ALL } from "../../constants/reviewConstants/Review.Constants";
import { CLEAR_ORIGINATION_ADDRESS, SET_ORIGINATION_ADDRESS } from "../../constants/shipmentAddressConstants/ShipmentAddress.Constants";

const originationAddressReducer = (state = {}, action: any) => {
    switch (action.type) {
        case SET_ORIGINATION_ADDRESS:
            return action.payload;
        case CLEAR_ORIGINATION_ADDRESS:
            return {};
        case CLEAR_ALL:
            return {};
        default:
            return state;
    }
};

export default originationAddressReducer;