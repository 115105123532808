import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { ReduxState } from "../../../redux/reducers/_rootReducer/_ReduxState";
import WindowWidth from "../WindowWidth/WindowWidth";
function Content({ element }: { element: ReactNode }) {
    const width = WindowWidth();
    const pageElements = useSelector((state: ReduxState) => state.PageElementsReducer);
    return (
        <div
            className={`App-content`}
            style={
                pageElements.isNavbarOpen && width > 1000
                    ? {
                          left: "var(--app-nav-width)",
                          width: "calc(100vw - var(--app-nav-width))",
                      }
                    : { left: "0px", width: "100vw" }
            }
        >
            <div className="container d-flex justify-content-center">{element}</div>
        </div>
    );
}
export default Content;