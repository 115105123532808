import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { clearAdditionalVendorContactInfoSet } from "../../redux/reducers/additionalVendorContactInfoReducer/AdditionalVendorContactInfo.Reducer";
import { clearShipmentPoSet } from "../../redux/reducers/shipmentPoReducer/ShipmentPo.Reducer";
import "../css/VendorPagesShared.css";
import Cookies from "js-cookie";
import { CLEAR_ALL } from "../../redux/constants/reviewConstants/Review.Constants";

function VendorHomePage() {
    
    const navigate = useNavigate();
    const dispatch = useDispatch();

    Cookies.remove("jwt");

    function beginProcess() {
        navigate("/VendorInfoPage");
    }

    useEffect(() => {
        dispatch({ type: clearAdditionalVendorContactInfoSet });
        dispatch({ type: clearShipmentPoSet });
        dispatch({ type: CLEAR_ALL });
    }, [dispatch]);

    return (
        <>
            <div style={{ width: "80%" }}>
                <h1 className="center-text">Welcome</h1>
                <img src={require("../../components/images/Scheels_Semi.png")} alt={"It borked! 🤷‍♀️"} className="col-12" />
                <h3 className="center-text">This is the Scheels vendor logistics portal. </h3>
                <Button id="vendor-btn" onClick={() => beginProcess()} size="block" className="col-3">
                    Continue as Vendor
                </Button>
            </div>
        </>
    );
}

export default VendorHomePage;
