import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { removeLastNotification } from "../../redux/reducers/notificationReducer/notification.reducer";
import { ReduxState } from "../../redux/reducers/_rootReducer/_ReduxState";

export const useNotifications = () => {
    const dispatch = useDispatch();

    const notificationReducer = useSelector((store: ReduxState) => store.notificationReducer);

    useEffect(() => {
        if (notificationReducer.length > 0) {
            const lastNotification = notificationReducer[notificationReducer.length - 1];
            if (typeof toast[lastNotification.type] === "function") toast[lastNotification.type](lastNotification.message, { progress: undefined });

            dispatch(removeLastNotification());
        }
    }, [notificationReducer, dispatch]);
};