export const FETCH_SHIPMENTS = "FETCH_SHIPMENTS";
export const FETCH_SELECTED_SHIPMENT = "FETCH_SELECTED_SHIPMENT";
export const FETCH_SELECTED_SHIPMENT_WITH_DETAILS = "FETCH_SELECTED_SHIPMENT_WITH_DETAILS";
export const SET_SHIPMENT = "SET_SHIPMENT";
export const CREATE_SHIPMENT = "CREATE_SHIPMENT";
export const UPDATE_SHIPMENT = "UPDATE_SHIPMENT";
export const CLEAR_SHIPMENT = "CLEAR_SHIPMENT";
export const SUBMIT_SHIPMENT = "SUBMIT_SHIPMENT";
export const DUPLICATE_SHIPMENT = "DUPLICATE_SHIPMENT";
export const CLEAR_FOR_DUPLICATE = "CLEAR_FOR_DUPLICATE";
export const SHIPMENT_SUBMITTED = "SHIPMENT_SUBMITTED";
