import Shipment from "./Shipment";

export default class Carton {
    public id: number = 0;
    public shipmentId: number = 0;
    public shipment?: Shipment;
    public length: number = 0;
    public width: number = 0;
    public height: number = 0;
    public qty: number = 0;
  }
  