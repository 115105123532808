import { CLEAR_ALL, CLEAR_DUPLICATE, CLEAR_REVIEW, SET_DUPLICATE, SET_REVIEW } from "../../constants/reviewConstants/Review.Constants";

const reviewReducer = (state = { inReview: false, isDuplicate: false }, action: any) => {
    switch (action.type) {
        case SET_REVIEW:
            return { ...state, inReview: action.payload };
        case SET_DUPLICATE:
            return { inReview: !action.payload, isDuplicate: action.payload };
        case CLEAR_REVIEW:
            return { ...state, inReview: false };
        case CLEAR_DUPLICATE:
            return { ...state, isDuplicate: false };
        case CLEAR_ALL:
            return { inReview: false, isDuplicate: false };
        default:
            return state;
    }
};

export default reviewReducer;
