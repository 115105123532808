import PalletFreightClassification from "./PalletFreightClassification";
import Shipment from "./Shipment";

export default class Pallet {
    public id: number = 0;
    public shipmentId: number = 0;
    public shipment?: Shipment;
    public length: number = 0;
    public width: number = 0;
    public height: number = 0;
    public weight: number = 0;
    public description: string = '';
    public cubicFeet: number = 0;
    public isStackable: boolean = false;
    public nmfcCode: string = ''
    public palletFreightClassification?: PalletFreightClassification = undefined;
    public quantity: number = 0;
  }
  