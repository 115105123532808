import { all } from "redux-saga/effects";
import AdditionalVendorContactInfoSaga from "../additionalVendorContactInfoSaga/AdditionalVendorContactInfo.Saga";
import NotificationSaga from "../notificationSaga/Notification.Saga";
import ShipmentPoSaga from "../shipmentPoSaga/ShipmentPo.Saga";
import ShipmentSaga from "../shipmentSaga/Shipment.Saga";
import OverrideSaga from "../overrideSaga/Override.Saga";
import CartonSaga from "../cartonSaga/Carton.Saga";
import PalletSaga from "../palletSaga/Pallet.Saga";
import OriginationZipLookupSaga from "../originationZipLookupSaga/OriginationZipLookup.Saga";
import DestinationZipLookupSaga from "../destinationZipLookupSaga/DestinationZipLookup.Saga";
import StoreSaga from "../storeSaga/Store.Saga";

export default function* rootSaga() {
    yield all([
        NotificationSaga(),
        ShipmentSaga(),
        AdditionalVendorContactInfoSaga(),
        ShipmentPoSaga(),
        OverrideSaga(),
        OriginationZipLookupSaga(),
        DestinationZipLookupSaga(),
        PalletSaga(),
        CartonSaga(),
        StoreSaga(),
    ]);
}