import { combineReducers } from "redux";
import PageElementsReducer from "../pageElementsReducer/PageElements.Reducer";
import LoadingReducer from "../loadingReducer/Loading.Reducer";
import AdditionalVendorContactInfoReducer from "../additionalVendorContactInfoReducer/AdditionalVendorContactInfo.Reducer";
import ShipmentPoReducer from "../shipmentPoReducer/ShipmentPo.Reducer";
import ShipmentReducer from "../shipmentReducer/Shipment.Reducer";
import OriginationAddressReducer from "../originationAddressReducer/OriginationAddress.Reducer";
import DestinationAddressReducer from "../destinationAddressReducer/DestinationAddress.Reducer";
import ShipmentInformationReducer from "../shipmentInformationReducer/ShipmentInformation.Reducer";
import PalletReducer from "../palletReducer/Pallet.Reducer";
import CartonReducer from "../cartonReducer/Carton.Reducer";
import DestinationZipLookupReducer from "../destinationZipLookupReducer/DestinationZipLookup.Reducer";
import OriginationZipLookupReducer from "../originationZipLookupReducer/OriginationZipLookup.Reducer";
import PoStoreReducer from "../poStoreReducer/PoStore.Reducer";
import notificationReducer from "../notificationReducer/notification.reducer";
import ReviewReducer from "../reviewReducer/Review.Reducer";

const RootReducer = combineReducers({
    PageElementsReducer,
    LoadingReducer,
    notificationReducer,
    ShipmentReducer,
    AdditionalVendorContactInfoReducer,
    ShipmentPoReducer,
    OriginationAddressReducer,
    DestinationAddressReducer,
    OriginationZipLookupReducer,
    DestinationZipLookupReducer,
    ShipmentInformationReducer,
    PalletReducer,
    CartonReducer,
    PoStoreReducer,
    ReviewReducer,
});

export default RootReducer;
