import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, Input, Spinner } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { CREATE_SHIPMENT, SET_SHIPMENT } from "../../redux/constants/shipmentConstants/Shipment.constants";
import { ReduxState } from "../../redux/reducers/_rootReducer/_ReduxState";
import Shipment from "../../models/Shipment";
import { validateEmail } from "../../components/Utilities";
import { iReviewInfo } from "../../models/IReviewInfo";
import "../App.css";

function VendorInfoPage() {
    const shipment: Shipment = useSelector((store: ReduxState) => store.ShipmentReducer);
    const loading: boolean = useSelector((store: ReduxState) => store.LoadingReducer);
    const reviewInfo: iReviewInfo = useSelector((store: ReduxState) => store.ReviewReducer);

    const [vendorContactName, setVendorContactName] = useState(shipment.primaryVendorContactName ?? "");
    const [vendorContactEmail, setVendorContactEmail] = useState(shipment.primaryVendorContactEmail ?? "");
    const [vendorContactPhone, setVendorContactPhone] = useState(shipment.primaryVendorContactPhone ?? "");
    const [allowContinue, setAllowContinue] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    function addVendorInfo() {
        if (!validateEmail(vendorContactEmail)) {
            window.alert("Please enter a valid email address");
            setVendorContactEmail("");
        } else {
            if (shipment.id > 0) {
                let updatedShipment: Shipment = {
                    id: shipment.id,
                    primaryVendorContactName: vendorContactName,
                    primaryVendorContactPhone: vendorContactPhone,
                    primaryVendorContactEmail: vendorContactEmail,
                    rocketShippingLoadId: 0,
                    createDate: shipment.createDate,
                    dockOpen: shipment.dockOpen,
                    dockClose: shipment.dockClose,
                    statusId: shipment.statusId,
                    numCartonsWithLengthOver48Inches: shipment.numCartonsWithLengthOver48Inches,
                    shipmentBatchId: shipment.shipmentBatchId,
                    totalCartons: shipment.totalCartons,
                    totalWeight: shipment.totalWeight,
                    pickupDateWindowStart: shipment.pickupDateWindowStart,
                    originName: shipment.originName,
                    originAddress1: shipment.originAddress1,
                    originAddress2: shipment.originAddress2,
                    originCity: shipment.originCity,
                    originState: shipment.originState,
                    originZip: shipment.originZip,
                    originComments: shipment.originComments,
                    destinationName: shipment.destinationName,
                    destinationAddress1: shipment.destinationAddress1,
                    destinationAddress2: shipment.destinationAddress2,
                    destinationCity: shipment.destinationCity,
                    destinationState: shipment.destinationState,
                    destinationZip: shipment.destinationZip,
                };
                dispatch({ type: SET_SHIPMENT, payload: updatedShipment });
            } else {
                dispatch({
                    type: CREATE_SHIPMENT,
                    payload: {
                        name: vendorContactName,
                        email: vendorContactEmail,
                        phone: vendorContactPhone,
                    },
                });
            }

            EmptyState();

            if (reviewInfo.inReview) {
                navigate("/ShipmentReviewPage");
            } else {
                navigate("/AdditionalVendorContactInfoPage");
            }
        }
    }

    useEffect(() => {
        if (vendorContactEmail === "" || vendorContactName === "" || vendorContactPhone === "") {
            setAllowContinue(true);
        } else {
            setAllowContinue(false);
        }
    }, [vendorContactEmail, vendorContactName, vendorContactPhone]);

    function EmptyState() {
        setVendorContactName("");
        setVendorContactEmail("");
        setVendorContactPhone("");
    }

    function GoBack(): void {
        navigate("/");
    }

    return (
        <>
            {loading ? (
                <>
                    <div className="k-w-full k-display-flex k-flex-column k-align-items-center">
                        <div>Loading...</div>
                        <Spinner color="danger" type="grow">
                            {" "}
                        </Spinner>
                    </div>
                </>
            ) : (
                <div style={{ width: "40%" }}>
                    <h3 className="center-text">Please, enter your contact information.</h3>
                    <div className="row col-12 d-flex justify-content-between">
                        <Input
                            required={true}
                            maxLength={128}
                            placeholder="Name"
                            value={vendorContactName}
                            onChange={(e) => setVendorContactName(e.target.value)}
                            autoComplete="name"
                        />
                        <Input
                            required={true}
                            maxLength={128}
                            placeholder="Email"
                            value={vendorContactEmail}
                            onChange={(e) => setVendorContactEmail(e.target.value)}
                            autoComplete="email"
                        />
                        <Input
                            required={true}
                            maxLength={10}
                            placeholder="Phone"
                            value={vendorContactPhone}
                            onChange={(e) => setVendorContactPhone(e.target.value)}
                            autoComplete="tel"
                        />
                        <Button
                            type="reset"
                            size="small"
                            color="outline-danger"
                            className="col-3"
                            style={{
                                backgroundColor: "white",
                                height: "40px",
                            }}
                            onClick={() => EmptyState()}
                        >
                            Clear
                        </Button>
                        <Button
                            type="submit"
                            size="small"
                            className="col-3"
                            disabled={allowContinue}
                            style={{
                                backgroundColor: "var(--app-primary)",
                                height: "40px",
                                marginLeft: "auto",
                            }}
                            onClick={() => addVendorInfo()}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            )}
            <div className="go-back-btn">
                <Button onClick={() => GoBack()}>Go Back</Button>
            </div>
        </>
    );
}

export default VendorInfoPage;
