import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import ShipmentInformationDetailsInput from "./ShipmentInformationDetailsInput";
import "../App.css";
import { usePageValidation } from "../../components/customHooks/usePageValidation";

function ShipmentInformationDetailsPage() {
    const navigate = useNavigate();

    function GoBack() {
        navigate("/ShipmentInformationPage");
    }

    usePageValidation();

    return (
        <>
            <div style={{ width: "100%" }}>
                <div>
                    <ShipmentInformationDetailsInput />
                </div>
            </div>
            <div className="go-back-btn">
                <Button onClick={() => GoBack()}>Go Back</Button>
            </div>
        </>
    );
}

export default ShipmentInformationDetailsPage;
