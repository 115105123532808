import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Spinner, Table } from "reactstrap";
import AdditionalVendorContactInfo from "../../models/AdditionalVendorContactInfo";
import Shipment from "../../models/Shipment";
import ShipmentPo from "../../models/ShipmentPo";
import { CLEAR_SHIPMENT, DUPLICATE_SHIPMENT, SHIPMENT_SUBMITTED, SUBMIT_SHIPMENT } from "../../redux/constants/shipmentConstants/Shipment.constants";
import { ReduxState } from "../../redux/reducers/_rootReducer/_ReduxState";
import "../ShipmentReviewPage/ShipmentReviewPage.css";
import { useNotifications } from "../../components/customHooks/useNotifications";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { iPageElements } from "../../redux/reducers/pageElementsReducer/PageElements.type";
import { SET_REVIEW } from "../../redux/constants/reviewConstants/Review.Constants";
import Pallet from "../../models/Pallet";
import { usePageValidation } from "../../components/customHooks/usePageValidation";
import { v4 as uuidv4 } from "uuid";
import { SET_SHIPMENT_ALREADY_SUBMITTED } from "../../redux/constants/shipmentInformationConstants/ShipmentInformation.Constants";

function ShipmentReviewPage() {
    const shipment: Shipment = useSelector((store: ReduxState) => store.ShipmentReducer);
    const shipmentPallets: Pallet[] = useSelector((store: ReduxState) => store.PalletReducer);
    const shipmentPos: ShipmentPo[] = useSelector((store: ReduxState) => store.ShipmentPoReducer);
    const additionalVendorContacts: AdditionalVendorContactInfo[] = useSelector((store: ReduxState) => store.AdditionalVendorContactInfoReducer);
    const pageElements: iPageElements = useSelector((store: ReduxState) => store.PageElementsReducer);
    const loading: boolean = useSelector((store: ReduxState) => store.LoadingReducer);
    const wasShipmentSuccessfull: boolean = useSelector((store: ReduxState) => store.ShipmentInformationReducer.shipmentSentSuccessfully);
    const wasShipmentAlreadySubmitted: boolean = useSelector((store: ReduxState) => store.ShipmentInformationReducer.shipmentAlreadySubmitted);

    const [showingModal, setShowingModal] = useState(false);
    const [isShipmentValid, setIsShipmentValid] = useState(false);
    const styleDarkMode = pageElements.isDarkTheme ? "k-text-light" : "";

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const formattedVendorPhoneNumber: string = `(${shipment?.primaryVendorContactPhone?.substring(0, 3)}) 
        ${shipment?.primaryVendorContactPhone?.substring(3, 6)}-${shipment?.primaryVendorContactPhone?.substring(6, 10)}`;

    function editParcels() {
        navigate("/ShipmentInformationPage");
    }

    function handleSubmitClick(): void {
        if (shipment.id !== undefined) {
            submitShipment();
        } else {
            window.alert("Something went wrong, Please try again.");
            goHome();
        }
    }

    useEffect(() => {
        if (wasShipmentSuccessfull) {
            setShowingModal(true);
            dispatch({ type: SHIPMENT_SUBMITTED, payload: false });
        }
    }, [wasShipmentSuccessfull, dispatch]);

    function submitShipment() {
        const finalShipment: Shipment = {
            id: shipment.id,
            primaryVendorContactName: shipment.primaryVendorContactName,
            primaryVendorContactPhone: shipment.primaryVendorContactPhone,
            primaryVendorContactEmail: shipment.primaryVendorContactEmail,
            rocketShippingLoadId: 0,
            createDate: shipment.createDate,
            dockOpen: shipment.dockOpen,
            dockClose: shipment.dockClose,
            statusId: 2,
            numCartonsWithLengthOver48Inches: shipment.numCartonsWithLengthOver48Inches,
            shipmentBatchId: 0,
            totalCartons: shipment.totalCartons,
            totalWeight: shipment.totalWeight,
            pickupDateWindowStart: shipment.pickupDateWindowStart,
            originName: shipment.originName,
            originAddress1: shipment.originAddress1,
            originAddress2: shipment.originAddress2,
            originCity: shipment.originCity,
            originState: shipment.originState,
            originZip: shipment.originZip,
            originComments: shipment.originComments,
            destinationName: shipment.destinationName,
            destinationAddress1: shipment.destinationAddress1,
            destinationAddress2: shipment.destinationAddress2,
            destinationCity: shipment.destinationCity,
            destinationState: shipment.destinationState,
            destinationZip: shipment.destinationZip,
        };

        dispatch({
            type: SUBMIT_SHIPMENT,
            payload: {
                finalShipment,
            },
        });
    }

    function goHome(): void {
        dispatch({ type: SET_SHIPMENT_ALREADY_SUBMITTED, payload: false });
        dispatch({ type: CLEAR_SHIPMENT });
        navigate("/", { replace: true });
    }

    function cloneShipment(): void {
        dispatch({ type: SET_SHIPMENT_ALREADY_SUBMITTED, payload: false });
        dispatch({ type: DUPLICATE_SHIPMENT, payload: shipmentPos[0]?.shipmentId });
        navigate("/PoValidationPage", { replace: true });
    }

    useEffect(() => {
        dispatch({ type: SET_REVIEW, payload: true });
    }, [dispatch]);

    useEffect(() => {
        const propertiesToIgnore = new Set([
            "status",
            "emails",
            "cartons",
            "pallets",
            "shipmentPos",
            "shipmentStatusHistories",
            "originAddress2",
            "destinationAddress2",
            "originComments",
        ]);
        const propertiesWhereZeroIsInvalid = new Set(["totalWeight", "totalCartons"]);

        for (const [key, value] of Object.entries(shipment)) {
            if (propertiesToIgnore.has(key)) continue;
            if (value === null || value === undefined || value === "") {
                setIsShipmentValid(false);
                break;
            }
            if (propertiesWhereZeroIsInvalid.has(key) && value <= 0) {
                setIsShipmentValid(false);
                break;
            }
            setIsShipmentValid(true);
        }

        if (shipmentPos?.length <= 0) {
            setIsShipmentValid(false);
        }
    }, [shipment, shipmentPos?.length]);

    usePageValidation();
    useNotifications();
    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme={pageElements.isDarkTheme ? "dark" : "light"}
                style={{ width: "800px" }}
            />
            {loading ? (
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        position: "absolute",
                        flexDirection: "column",
                        alignItems: "center",
                        top: "40%",
                    }}
                >
                    <div>Loading...</div>
                    <Spinner color="danger" type="grow">
                        {" "}
                    </Spinner>
                </div>
            ) : (
                <div style={{ width: "100%", textAlign: "center" }}>
                    {!showingModal ? (
                        <>
                            <div style={{ display: "inline-block" }}>
                                <h1 style={{ paddingLeft: "3em" }} className={styleDarkMode}>
                                    Shipment Review
                                </h1>
                            </div>
                            <div
                                style={{
                                    display: "inline-block",
                                    position: "relative",
                                    left: "3em",
                                    bottom: "0.25em",
                                }}
                            >
                                {" "}
                                {!wasShipmentAlreadySubmitted && (
                                    <Button disabled={!isShipmentValid || wasShipmentAlreadySubmitted} color="success" onClick={() => handleSubmitClick()}>
                                        Submit Shipment
                                    </Button>
                                )}
                            </div>
                            <div className="parent-container">
                                <div className="col-12">
                                    {/* Primary Contact Table */}
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th colSpan={2}>
                                                    <h4 className="k-m-0" style={{ color: "red" }}>
                                                        Primary Contact
                                                    </h4>
                                                </th>
                                                <th style={{ width: "10%" }}>
                                                    {!wasShipmentAlreadySubmitted && (
                                                        <Button
                                                            style={{ float: "right" }}
                                                            color="warning"
                                                            size="small"
                                                            disabled={wasShipmentAlreadySubmitted}
                                                            onClick={() => navigate("/VendorInfoPage")}
                                                        >
                                                            Edit
                                                        </Button>
                                                    )}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan={3} style={{ border: "none", padding: "0" }}>
                                                    <h5 className={styleDarkMode} style={{ marginTop: "1em" }}>
                                                        {shipment.primaryVendorContactName}
                                                    </h5>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3} style={{ border: "none", padding: "0" }}>
                                                    <h5 className={styleDarkMode}>{shipment.primaryVendorContactEmail}</h5>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3} style={{ border: "none", padding: "0" }}>
                                                    <h5 className={styleDarkMode}>{formattedVendorPhoneNumber}</h5>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                                <div className="col-12">
                                    {/* Additional Contact Table */}
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <h4 className="k-m-0" style={{ color: "red" }}>
                                                        Additional Contacts
                                                    </h4>
                                                </th>
                                                <th style={{ width: "10%" }}>
                                                    {!wasShipmentAlreadySubmitted && (
                                                        <Button
                                                            style={{ float: "right" }}
                                                            color="warning"
                                                            size="small"
                                                            disabled={wasShipmentAlreadySubmitted}
                                                            onClick={() => navigate("/AdditionalVendorContactInfoPage")}
                                                        >
                                                            Edit
                                                        </Button>
                                                    )}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {additionalVendorContacts.length > 0 ? (
                                                additionalVendorContacts?.map((email, i: number) =>
                                                    i > 1 ? (
                                                        <tr key={uuidv4()}>
                                                            <td colSpan={2} className="k-p-0">
                                                                <h5 className={styleDarkMode + "k-mb-0"} style={{ marginTop: ".5rem" }}>
                                                                    {email.email}
                                                                </h5>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        <tr key={uuidv4()}>
                                                            <td colSpan={2} className="k-p-1">
                                                                <h5 className={styleDarkMode} style={{ marginTop: ".5rem" }}>
                                                                    {email.email}
                                                                </h5>
                                                            </td>
                                                        </tr>
                                                    )
                                                )
                                            ) : (
                                                <tr>
                                                    <td colSpan={2} className="k-p-0">
                                                        <h5 className={styleDarkMode} style={{ marginTop: "1em" }}>
                                                            No additional contacts added
                                                        </h5>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                                <div className="col-12">
                                    {/* Origination Address Table */}
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <h4 className="k-m-0" style={{ color: "red" }}>
                                                        Origination Address
                                                    </h4>
                                                </th>
                                                <th style={{ width: "10%" }}>
                                                    {!wasShipmentAlreadySubmitted && (
                                                        <Button
                                                            style={{ float: "right" }}
                                                            color="warning"
                                                            size="small"
                                                            disabled={wasShipmentAlreadySubmitted}
                                                            onClick={() => navigate("/ShipmentAddressInfoPage")}
                                                        >
                                                            Edit
                                                        </Button>
                                                    )}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan={3} style={{ border: "none", padding: "0" }}>
                                                    <h5 className={styleDarkMode} style={{ marginTop: "1em" }}>
                                                        {shipment.originName}
                                                    </h5>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3} style={{ border: "none", padding: "0" }}>
                                                    <h5 className={styleDarkMode}>{shipment.originAddress1}</h5>
                                                </td>
                                            </tr>
                                            {shipment.originAddress2 ? (
                                                <tr>
                                                    <td colSpan={3} style={{ border: "none", padding: "0" }}>
                                                        <h5 className={styleDarkMode}>{shipment.originAddress2}</h5>
                                                    </td>
                                                </tr>
                                            ) : null}
                                            <tr>
                                                <td colSpan={3} style={{ border: "none", padding: "0" }}>
                                                    <h5 className={styleDarkMode}>
                                                        {shipment.originCity}, {shipment.originState} {shipment.originZip}
                                                    </h5>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                                <div className="col-12">
                                    {/* Destination Address Table */}
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <h4 className="k-m-0" style={{ color: "red" }}>
                                                        Destination Address
                                                    </h4>
                                                </th>
                                                <th style={{ width: "10%" }}>
                                                    {!wasShipmentAlreadySubmitted && (
                                                        <Button
                                                            style={{ float: "right" }}
                                                            color="warning"
                                                            size="small"
                                                            disabled={wasShipmentAlreadySubmitted}
                                                            onClick={() => navigate("/ShipmentAddressInfoPage")}
                                                        >
                                                            Edit
                                                        </Button>
                                                    )}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan={3} style={{ border: "none", padding: "0" }}>
                                                    <h5 className={styleDarkMode} style={{ marginTop: "1em" }}>
                                                        {shipment.destinationName}
                                                    </h5>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3} style={{ border: "none", padding: "0" }}>
                                                    <h5 className={styleDarkMode}>{shipment.destinationAddress1}</h5>
                                                </td>
                                            </tr>
                                            {shipment.destinationAddress2 ? (
                                                <tr>
                                                    <td colSpan={3} style={{ border: "none", padding: "0" }}>
                                                        <h5 className={styleDarkMode}>{shipment.destinationAddress2}</h5>
                                                    </td>
                                                </tr>
                                            ) : null}
                                            <tr>
                                                <td colSpan={3} style={{ border: "none", padding: "0" }}>
                                                    <h5 className={styleDarkMode}>
                                                        {shipment.destinationCity}, {shipment.destinationState} {shipment.destinationZip}
                                                    </h5>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>

                                <div className="col-12">
                                    {/* Shipment POs Table */}
                                    <Table className="k-m-0">
                                        <thead>
                                            <tr>
                                                <th colSpan={2}>
                                                    <h4 className="k-m-0" style={{ color: "red" }}>
                                                        PO(s) on Shipment
                                                    </h4>
                                                </th>
                                                <th style={{ width: "10%" }}>
                                                    {!wasShipmentAlreadySubmitted && (
                                                        <Button
                                                            style={{ float: "right" }}
                                                            color="warning"
                                                            size="small"
                                                            disabled={wasShipmentAlreadySubmitted}
                                                            onClick={() => navigate("/PoValidationPage")}
                                                        >
                                                            Edit
                                                        </Button>
                                                    )}
                                                </th>
                                            </tr>
                                        </thead>
                                    </Table>
                                    <Table>
                                        <tbody>
                                            <tr>
                                                <td style={{ border: "none", padding: ".5rem", width: "48%" }}>
                                                    <h5 className={styleDarkMode + " k-m-0"}>PO #</h5>
                                                </td>
                                                <td style={{ border: "none", padding: ".5rem", width: "48%" }}>
                                                    <h5 className={styleDarkMode + " k-m-0"}>Manual PO</h5>
                                                </td>
                                                <td style={{ border: "none", padding: ".5rem", width: "48%" }}>
                                                    <h5 className={styleDarkMode + " k-m-0"}>Value</h5>
                                                </td>
                                            </tr>

                                            {shipmentPos?.map((po, index) =>
                                                index === 0 ? (
                                                    <tr key={uuidv4()}>
                                                        <td className="k-w-5/12 k-p-0">
                                                            <h5 className={styleDarkMode}>{po.po === 0 ? <>N/A</> : <>{po.po}</>}</h5>
                                                        </td>
                                                        <td className="k-w-5/12 k-p-0">
                                                            <h5 className={styleDarkMode}>{po.manualPo === "" ? <>N/A</> : <> {po.manualPo} </>}</h5>
                                                        </td>
                                                        <td className="k-w-5/12 k-p-0">
                                                            <h5 className={styleDarkMode}>{!po.value || po.value <= 0 ? <>N/A</> : <> {po.value} </>}</h5>
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    <tr key={uuidv4()}>
                                                        <td className="k-w-5/12 k-p-0" style={{ border: "none" }}>
                                                            <h5 className={styleDarkMode}>{po.po === 0 ? <>N/A</> : <>{po.po}</>}</h5>
                                                        </td>
                                                        <td className="k-w-5/12 k-p-0" style={{ border: "none" }}>
                                                            <h5 className={styleDarkMode}>{po.manualPo === "" ? <>N/A</> : <> {po.manualPo} </>}</h5>
                                                        </td>
                                                        <td className="k-w-5/12 k-p-0" style={{ border: "none" }}>
                                                            <h5 className={styleDarkMode}>
                                                                {!po.value || po.value <= 0 ? <>N/A</> : <> ${po.value?.toFixed(2)} </>}
                                                            </h5>
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                                <div className="col-12">
                                    {/* Shipment Pallets Table */}
                                    <Table className="k-m-0">
                                        <thead>
                                            <tr>
                                                <th colSpan={2} style={{ paddingRight: "3rem" }}>
                                                    <h4 className="k-m-0" style={{ color: "red" }}>
                                                        Pallets/Cartons
                                                    </h4>
                                                </th>
                                                <th style={{ width: "10%" }}>
                                                    {!wasShipmentAlreadySubmitted && (
                                                        <Button
                                                            style={{ float: "right" }}
                                                            color="warning"
                                                            size="small"
                                                            disabled={wasShipmentAlreadySubmitted}
                                                            onClick={() => editParcels()}
                                                        >
                                                            Edit
                                                        </Button>
                                                    )}
                                                </th>
                                            </tr>
                                        </thead>
                                    </Table>
                                    <Table>
                                        <tbody>
                                            <tr>
                                                <td style={{ border: "none", padding: ".5rem", width: "33%" }}>
                                                    <h5 className={styleDarkMode + " k-m-0"}>Pallet Count</h5>
                                                </td>
                                                <td style={{ border: "none", padding: ".5rem", width: "33%" }}>
                                                    <h5 className={styleDarkMode + " k-m-0"}>Carton Count</h5>
                                                </td>
                                                <td style={{ border: "none", padding: ".5rem", width: "33%" }}>
                                                    <h5 className={styleDarkMode + " k-m-0"}>Total Weight</h5>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={styleDarkMode + " k-p-0"}>{shipmentPallets.length}</td>
                                                <td className={styleDarkMode + " k-p-0"}>{shipment.totalCartons}</td>
                                                <td className={styleDarkMode + " k-p-0"}>{shipment?.totalWeight?.toFixed(3)}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="modal-container">
                            <h3>Thank you for your routing request.</h3>
                            <h1>{`Confirmation Number: ${shipment.id}`}</h1>
                            <h5>For changes or cancellation requests, contact logistics@scheels.com with your confirmation number</h5>
                            <Button color="warning" onClick={() => cloneShipment()}>
                                Another Shipment
                            </Button>
                            <Button color="success" onClick={() => goHome()}>
                                Home
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

export default ShipmentReviewPage;
