import { CLEAR_PO_STORE, SET_PO_STORE } from "../../constants/poValidationConstants/PoValidation.Constants";
import { CLEAR_ALL } from "../../constants/reviewConstants/Review.Constants";
import { CLEAR_FOR_DUPLICATE } from "../../constants/shipmentConstants/Shipment.constants";

const poStoreReducer = (state = {}, action: any) => {
    switch (action.type) {
        case SET_PO_STORE:
            return action.payload;
        case CLEAR_PO_STORE:
            return {};
        case CLEAR_ALL:
            return {};
        case CLEAR_FOR_DUPLICATE:
            return {};
        default:
            return state;
    }
};

export default poStoreReducer;
