import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "reactstrap";
import Pallet from "../../models/Pallet";
import { DELETE_SHIPMENT_PALLET } from "../../redux/constants/palletConstants/pallet.Constants";
import { ReduxState } from "../../redux/reducers/_rootReducer/_ReduxState";
import Shipment from "../../models/Shipment";
import iShipmentInformation from "../../models/IShipmentInformation";
import { SET_SHIPMENT } from "../../redux/constants/shipmentConstants/Shipment.constants";
import { useNavigate } from "react-router-dom";
import "./ShipmentInformationDetails.css";
import { iPageElements } from "../../redux/reducers/pageElementsReducer/PageElements.type";
import { v4 as uuidv4 } from "uuid";

function ShipmentPalletSnapshot() {
    const shipmentPallets: Pallet[] = useSelector((store: ReduxState) => store.PalletReducer);
    const shipment: Shipment = useSelector((store: ReduxState) => store.ShipmentReducer);
    const shipmentInfo: iShipmentInformation = useSelector((store: ReduxState) => store.ShipmentInformationReducer);
    const pageElements: iPageElements = useSelector((store: ReduxState) => store.PageElementsReducer);

    const [totalShipmentWeight, setTotalShipmentWeight] = useState(0);

    const styleDarkMode = pageElements.isDarkTheme ? "k-text-light" : "";

    const dispatch = useDispatch();
    const navigate = useNavigate();

    function deletePallet(id: number): void {
        dispatch({
            type: DELETE_SHIPMENT_PALLET,
            payload: {
                palletId: id,
                shipmentId: shipment.id,
            },
        });
    }

    function savePallets() {
        shipment.totalWeight = totalShipmentWeight;
        dispatch({ type: SET_SHIPMENT, payload: shipment });
        navigate("/ShipmentReviewPage");
    }

    useEffect(() => {
        let currentShipmentWeight = 0;
        shipmentPallets.forEach((pallet) => {
            currentShipmentWeight += pallet.weight;
        });
        setTotalShipmentWeight(currentShipmentWeight);
    }, [shipmentPallets]);

    return (
        <>
            <Table>
                <thead>
                    <tr>
                        <th colSpan={5}>
                            <h2>
                                <p className={styleDarkMode + " k-m-0"}>
                                    Pallets: {shipmentPallets.length}/{shipmentInfo.totalPallets} Entered
                                </p>
                            </h2>
                        </th>
                        <th colSpan={2}>
                            {shipmentPallets.length !== Number(shipmentInfo.totalPallets) ? (
                                <></>
                            ) : (
                                <Button color="success" disabled={shipmentPallets.length !== Number(shipmentInfo.totalPallets)} onClick={() => savePallets()}>
                                    Review Shipment
                                </Button>
                            )}
                        </th>
                    </tr>
                </thead>
            </Table>
            {shipmentPallets.length > 0 ? (
                <div>
                    <div className="custom-table-container">
                        <div className="custom-table-header">
                            <Table>
                                <thead>
                                    <tr>
                                        <td style={{ width: "80px" }} className="k-px-0 k-text-center">
                                            <h6 className={styleDarkMode}>Length</h6>
                                        </td>
                                        <td style={{ width: "80px" }} className="k-px-0 k-text-center">
                                            <h6 className={styleDarkMode}>Width</h6>
                                        </td>
                                        <td style={{ width: "80px" }} className="k-px-0 k-text-center">
                                            <h6 className={styleDarkMode}>Height</h6>
                                        </td>
                                        <td style={{ width: "80px" }} className="k-px-0 k-text-center">
                                            <h6 className={styleDarkMode}>Cubes</h6>
                                        </td>
                                        <td style={{ width: "80px" }} className="k-px-0 k-text-center">
                                            <h6 className={styleDarkMode}>Stackable</h6>
                                        </td>
                                        <td style={{ width: "80px" }} className="k-px-0 k-text-center">
                                            <h6 className={styleDarkMode}>Weight</h6>
                                        </td>
                                        <td style={{ width: "80px" }}></td>
                                    </tr>
                                </thead>
                            </Table>
                        </div>
                        <div className="custom-table-body">
                            <Table>
                                <tbody>
                                    {shipmentPallets?.map((pallet) => (
                                        <tr key={uuidv4()}>
                                            <td style={{ width: "80px" }} className={styleDarkMode + " k-px-0 k-text-center"}>
                                                <h6>{pallet.length}</h6>
                                            </td>
                                            <td style={{ width: "80px" }} className={styleDarkMode + " k-px-0 k-text-center"}>
                                                <h6>{pallet.width}</h6>
                                            </td>
                                            <td style={{ width: "80px" }} className={styleDarkMode + " k-px-0 k-text-center"}>
                                                <h6>{pallet.height}</h6>
                                            </td>
                                            <td style={{ width: "80px" }} className={styleDarkMode + " k-px-0 k-text-center"}>
                                                <h6>{pallet.cubicFeet}</h6>
                                            </td>
                                            <td style={{ width: "80px" }} className={styleDarkMode + " k-px-0 k-text-center"}>
                                                <h6>{pallet.isStackable ? "Yes" : "No"}</h6>
                                            </td>
                                            <td style={{ width: "77px" }} className={styleDarkMode + " k-px-0 k-text-center"}>
                                                <h6>{pallet.weight} lbs</h6>
                                            </td>
                                            <td style={{ width: "80px" }} className={styleDarkMode + " k-px-0 k-text-center"}>
                                                <Button
                                                    style={{
                                                        backgroundColor: "#a12",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        float: "right",
                                                        height: "1.5em",
                                                        width: "100%",
                                                    }}
                                                    size="small"
                                                    onClick={() => deletePallet(pallet.id)}
                                                >
                                                    Delete
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div className="weight">
                        <h5>Total Shipment Weight:</h5>
                        <h3 className="weight-details">{totalShipmentWeight} lbs</h3>
                    </div>
                </div>
            ) : null}
        </>
    );
}

export default ShipmentPalletSnapshot;
