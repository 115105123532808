import { Alert, Col, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { AuthenticationStatus } from "../../redux/constants/loadingStatusConstants/loadingStatus.constants";
import { ReduxState } from "../../redux/reducers/_rootReducer/_ReduxState";
import { GoogleLogin } from "@react-oauth/google";
import { ValidateGoogleUser } from "../../redux/constants/userConstants/User.constants";

function GoogleLogins() {
    const dispatch = useDispatch();
    const loginStatus: AuthenticationStatus = useSelector((store: ReduxState) => store.LoginReducer);
    const [googleResponse, setGoogleResponse] = useState("");

    useEffect(() => {
        if (googleResponse) {
            dispatch(ValidateGoogleUser(googleResponse));
        }
    }, [googleResponse, dispatch]);

    const user = useSelector((state: ReduxState) => state.UserReducer);

    const responseSuccessGoogle = async (googleResponse: any) => {
        setGoogleResponse(googleResponse.credential);
    };

    return (
        <>
            {!user.employeeId && (
                <div style={{ width: "100%" }} className="d-flex justify-content-center">
                    <GoogleLogin useOneTap onSuccess={(e) => responseSuccessGoogle(e)} size="large" />
                </div>
            )}
            {loginStatus === AuthenticationStatus.Failed ? (
                <Row>
                    <Col>
                        <Alert color="danger"></Alert>
                    </Col>
                </Row>
            ) : (
                <></>
            )}
        </>
    );
}

export default GoogleLogins;
