import axios from "axios";
import { put, takeEvery } from "redux-saga/effects";
import Override from "../../../models/Override";
import ShipmentPo from "../../../models/ShipmentPo";
import { IS_LOADING } from "../../constants/loadingStatusConstants/loadingStatus.constants";
import { ERROR_NOTIFICATION } from "../../constants/notificationConstants/notification.constants";
import { ADD_OVERRIDE } from "../../constants/overrideConstants/override.constants";
import { FETCH_SHIPMENT_POS } from "../../constants/poValidationConstants/PoValidation.Constants";

function* addOverride(action: any) {
    yield put({ type: IS_LOADING, payload: true });
    try {
        const shipmentPo: ShipmentPo = action.payload.newShipmentPo;
        const newOverride: Override = action.payload.newOverride;
        const request: string = `${process.env.REACT_APP_API_URL}/Shipment/${shipmentPo.shipmentId}/ShipmentPo/${newOverride.ShipmentPoId}/Override`;
        yield axios.post(request, newOverride);
        yield put({ type: FETCH_SHIPMENT_POS, payload: shipmentPo.shipmentId });
    } catch (error) {
        yield put({ type: ERROR_NOTIFICATION, payload: "Failed to add PO override." });
    }
    yield put({ type: IS_LOADING, payload: false });
}

function* overrideSaga() {
    yield takeEvery(ADD_OVERRIDE, addOverride);
}

export default overrideSaga;
