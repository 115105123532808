import AdditionalVendorContactInfoPage from "../../pages/AdditionalVendorContactInfoPage/AdditionalVendorContactInfoPage";
import PoValidationPage from "../../pages/PoValidationPage/PoValidationPage";
import ShipmentAddressInfoPage from "../../pages/ShipmentAddressInfoPage/ShipmentAddressInfoPage";
import ShipmentInformationDetailsPage from "../../pages/ShipmentInformationDetailsPage/ShipmentInformationDetailsPage";
import ShipmentInformationPage from "../../pages/ShipmentInformationPage/ShipmentInformationPage";
import ShipmentReviewPage from "../../pages/ShipmentReviewPage/ShipmentReviewPage";
import VendorHomePage from "../../pages/VendorHomePage/VendorHomePage";
import VendorInfoPage from "../../pages/VendorInfoPage/VendorInfoPage";
import { iNavbarLinkProps } from "./Navbar/NavbarLink";

export const pageTitle = "Scheels Logistics Portal";
export const navigationLinks: iNavbarLinkProps[] = [
    {
        link: "",
        title: "Home",
        children: [],
        element: VendorHomePage,
        isHidden: false,
        isProtected: false,
    },
    {
        link: "VendorInfoPage",
        title: "Vendor Info",
        children: [],
        element: VendorInfoPage,
        isHidden: true,
        isProtected: false,
    },
    {
        link: "AdditionalVendorContactInfoPage",
        title: "Additional Vendor Contact Info",
        children: [],
        element: AdditionalVendorContactInfoPage,
        isHidden: true,
        isProtected: false,
    },
    {
        link: "PoValidationPage",
        title: "PO Validation",
        children: [],
        element: PoValidationPage,
        isHidden: true,
        isProtected: false,
    },
    {
        link: "ShipmentAddressInfoPage",
        title: "Shipment Address Info",
        children: [],
        element: ShipmentAddressInfoPage,
        isHidden: true,
        isProtected: false,
    },
    {
        link: "ShipmentInformationPage",
        title: "Shipment Information",
        children: [],
        element: ShipmentInformationPage,
        isHidden: true,
        isProtected: false,
    },
    {
        link: "ShipmentInformationDetailsPage",
        title: "Shipment Information Details",
        children: [],
        element: ShipmentInformationDetailsPage,
        isHidden: true,
        isProtected: false,
    },
    {
        link: "ShipmentReviewPage",
        title: "Shipment Review Page",
        children: [],
        element: ShipmentReviewPage,
        isHidden: true,
        isProtected: false,
    }
];
