import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Input, Spinner, Table } from "reactstrap";
import { useNotifications } from "../../components/customHooks/useNotifications";
import AdditionalVendorContactInfo from "../../models/AdditionalVendorContactInfo";
import Override from "../../models/Override";
import Shipment from "../../models/Shipment";
import ShipmentPo from "../../models/ShipmentPo";
import Store from "../../models/Store";
import { ADD_SHIPMENT_PO, CLEAR_PO_STORE, REMOVE_SHIPMENT_PO, VALIDATE_SHIPMENT_PO } from "../../redux/constants/poValidationConstants/PoValidation.Constants";
import { CLEAR_DESTINATION_ADDRESS } from "../../redux/constants/shipmentAddressConstants/ShipmentAddress.Constants";
import { ReduxState } from "../../redux/reducers/_rootReducer/_ReduxState";
import "../PoValidationPage/PoValidationPage.css";
import { iReviewInfo } from "../../models/IReviewInfo";
import { SET_SHIPMENT } from "../../redux/constants/shipmentConstants/Shipment.constants";
import "../App.css";
import { v4 as uuidv4 } from "uuid";

function PoValidationPage() {
    const pageElements = useSelector((store: ReduxState) => store.PageElementsReducer);
    const shipment: Shipment = useSelector((store: ReduxState) => store.ShipmentReducer);
    const additionalVendorContacts: AdditionalVendorContactInfo[] = useSelector((store: ReduxState) => store.AdditionalVendorContactInfoReducer);
    const loading: boolean = useSelector((store: ReduxState) => store.LoadingReducer);
    const poStore: Store = useSelector((store: ReduxState) => store.PoStoreReducer);
    const shipmentPos: ShipmentPo[] = useSelector((store: ReduxState) => store.ShipmentPoReducer);
    const reviewInfo: iReviewInfo = useSelector((store: ReduxState) => store.ReviewReducer);

    const [earlyShip, setEarlyShip] = useState(false);
    const [manualPoModalVisibility, setManualPoModalVisibility] = useState(false);
    const [modalVisibility, setModalVisibility] = useState(false);
    const [overridePoNumber, setOverridePoNumber] = useState("");
    const [poNumber, setPoNumber] = useState("");
    const [scheelsAssociate, setScheelsAssociate] = useState("");
    const [storeLocation, setStoreLocation] = useState("");
    const [value, setValue] = useState<number>();

    const vendorContactName: string = shipment.primaryVendorContactName || "Loading";
    const vendorEmail: string = shipment.primaryVendorContactEmail || "Loading";
    const formattedVendorPhoneNumber = `(${shipment?.primaryVendorContactPhone?.substring(0, 3)}) 
        ${shipment?.primaryVendorContactPhone?.substring(3, 6)}-${shipment?.primaryVendorContactPhone?.substring(6, 10)}`;
    const styleDarkMode = pageElements.isDarkTheme ? "k-text-light" : "";
    const styleDarkModeOnWhite = pageElements.isDarkTheme ? "k-text-dark" : "";

    const navigate = useNavigate();
    const dispatch = useDispatch();

    let shipmentToUpdate: Shipment = new Shipment();

    let newShipmentPo: ShipmentPo = {
        shipmentId: shipment.id,
        id: 0,
        po: 0,
        manualPo: "",
        validated: false,
        override: new Override(),
    };

    function toggleModalVisibility() {
        setScheelsAssociate("");
        setStoreLocation("");
        setPoNumber("");
        setModalVisibility(!modalVisibility);
        if (shipmentPos.length <= 0) {
            dispatch({ type: CLEAR_PO_STORE });
            dispatch({ type: CLEAR_DESTINATION_ADDRESS });
        }
    }

    function toggleManualPoModalVisibility() {
        setManualPoModalVisibility(!manualPoModalVisibility);
    }

    function validateShipmentPo() {
        const parsedInt = parseInt(poNumber);
        if (!Number.isNaN(parsedInt)) newShipmentPo.po = parsedInt;

        dispatch({
            type: VALIDATE_SHIPMENT_PO,
            payload: {
                setPoNumber: setPoNumber,
                newShipmentPo: newShipmentPo,
                toggleModalVisibility: toggleModalVisibility,
                toggleManualPoModalVisibility: toggleManualPoModalVisibility,
                setEarlyShip: setEarlyShip,
                poStoreNum: poStore.storeNumber,
            },
        });
        setOverridePoNumber(poNumber);
        setPoNumber("");
    }

    function removeShipmentPo(id: number) {
        dispatch({
            type: REMOVE_SHIPMENT_PO,
            payload: {
                shipmentPoId: id,
                shipmentId: shipment.id,
            },
        });
        if (shipmentPos.length === 1) {
            shipmentToUpdate = {
                id: shipment.id,
                primaryVendorContactName: shipment.primaryVendorContactName,
                primaryVendorContactPhone: shipment.primaryVendorContactPhone,
                primaryVendorContactEmail: shipment.primaryVendorContactEmail,
                rocketShippingLoadId: 0,
                createDate: shipment.createDate,
                dockOpen: shipment.dockOpen,
                dockClose: shipment.dockClose,
                statusId: shipment.statusId,
                numCartonsWithLengthOver48Inches: shipment.numCartonsWithLengthOver48Inches,
                shipmentBatchId: 0,
                totalCartons: shipment.totalCartons,
                totalWeight: shipment.totalWeight,
                pickupDateWindowStart: shipment.pickupDateWindowStart,
                originName: shipment.originName,
                originAddress1: shipment.originAddress1,
                originAddress2: shipment.originAddress2,
                originCity: shipment.originCity,
                originState: shipment.originState,
                originZip: shipment.originZip,
                originComments: shipment.originComments,
                destinationName: "",
                destinationAddress1: "",
                destinationAddress2: "",
                destinationCity: "",
                destinationState: "",
                destinationZip: "",
            };
            dispatch({ type: SET_SHIPMENT, payload: shipmentToUpdate });
            dispatch({ type: CLEAR_PO_STORE });
            dispatch({ type: CLEAR_DESTINATION_ADDRESS });
        }
    }

    function submitOverride() {
        newShipmentPo.po = parseInt(overridePoNumber);

        const newOverride: Override = {
            Id: 0,
            ShipmentPoId: 0,
            AssociateToApproveOverride: scheelsAssociate,
            AssociateLocation: storeLocation,
            OverrideTypeId: 0,
        };

        if (earlyShip) newOverride.OverrideTypeId = 1;
        else newOverride.OverrideTypeId = 2;

        dispatch({
            type: ADD_SHIPMENT_PO,
            payload: {
                newShipmentPo: newShipmentPo,
                newOverride: newOverride,
                poStoreNum: poStore.storeNumber,
            },
        });

        toggleModalVisibility();
        setPoNumber("");
    }

    function updateShipment() {
        shipmentToUpdate.id = shipment.id;
        shipmentToUpdate.primaryVendorContactName = shipment.primaryVendorContactName;
        shipmentToUpdate.primaryVendorContactPhone = shipment.primaryVendorContactPhone;
        shipmentToUpdate.primaryVendorContactEmail = shipment.primaryVendorContactEmail;
        shipmentToUpdate.rocketShippingLoadId = 0;
        shipmentToUpdate.createDate = shipment.createDate;
        shipmentToUpdate.dockOpen = shipment.dockOpen;
        shipmentToUpdate.dockClose = shipment.dockClose;
        shipmentToUpdate.statusId = shipment.statusId;
        shipmentToUpdate.numCartonsWithLengthOver48Inches = shipment.numCartonsWithLengthOver48Inches;
        shipmentToUpdate.shipmentBatchId = 0;
        shipmentToUpdate.totalCartons = shipment.totalCartons;
        shipmentToUpdate.totalWeight = shipment.totalWeight;
        shipmentToUpdate.pickupDateWindowStart = shipment.pickupDateWindowStart;
        shipmentToUpdate.originName = shipment.originName;
        shipmentToUpdate.originAddress1 = shipment.originAddress1;
        shipmentToUpdate.originAddress2 = shipment.originAddress2;
        shipmentToUpdate.originCity = shipment.originCity;
        shipmentToUpdate.originState = shipment.originState;
        shipmentToUpdate.originZip = shipment.originZip;
        shipmentToUpdate.originComments = shipment.originComments;
        shipmentToUpdate.destinationName = poStore.storeName;
        shipmentToUpdate.destinationAddress1 = poStore.storeAddressLine1;
        shipmentToUpdate.destinationAddress2 = poStore.storeAddressLine2;
        shipmentToUpdate.destinationCity = poStore.storeCity;
        shipmentToUpdate.destinationState = poStore.storeState;
        shipmentToUpdate.destinationZip = poStore.storeZipCode;
        dispatch({ type: SET_SHIPMENT, payload: shipmentToUpdate });
        setPoNumber("");

        if (reviewInfo.inReview) {
            navigate("/ShipmentReviewPage");
        } else if (reviewInfo.isDuplicate && poStore.storeNumber > 0) {
            navigate("/ShipmentInformationPage");
        } else {
            navigate("/ShipmentAddressInfoPage");
        }
    }

    function cancelManualPo() {
        setPoNumber("");
        toggleManualPoModalVisibility();
    }

    function addManualPo() {
        newShipmentPo = {
            shipmentId: shipment.id,
            id: 0,
            po: 0,
            manualPo: overridePoNumber,
            validated: false,
            override: new Override(),
            value,
        };

        dispatch({
            type: ADD_SHIPMENT_PO,
            payload: {
                newShipmentPo: newShipmentPo,
                poStoreNum: 0,
            },
        });

        setPoNumber("");
        toggleManualPoModalVisibility();
    }

    function GoBack() {
        navigate("/AdditionalVendorContactInfoPage");
    }

    useNotifications();

    return (
        <>
            {loading ? (
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        position: "absolute",
                        flexDirection: "column",
                        alignItems: "center",
                        top: "40%",
                    }}
                >
                    <div>Loading...</div>
                    <Spinner color="danger" type="grow">
                        {" "}
                    </Spinner>
                </div>
            ) : (
                <>
                    <ToastContainer
                        position="top-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme={pageElements.isDarkTheme ? "dark" : "light"}
                    />
                    <div className="col-12">
                        <div style={{ display: "flex", width: "100%" }}>
                            <div style={{ width: "50%" }}>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>
                                                <h2 className={styleDarkMode}>Primary Contact:</h2>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <h5 className={styleDarkMode + " contact_info contact_top"}>{vendorContactName}</h5>
                                                <h5 className={styleDarkMode + " contact_info"}>{vendorEmail}</h5>
                                                <h5 className={styleDarkMode + " contact_info"}>{formattedVendorPhoneNumber}</h5>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                            {additionalVendorContacts.length > 0 ? (
                                <div style={{ width: "50%" }}>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <h2 className={styleDarkMode}>Additional Contacts:</h2>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {additionalVendorContacts?.map((vendorContact) => (
                                                <tr key={uuidv4()}>
                                                    <td>
                                                        <h5 className={styleDarkMode + " contact_info contact_top"}>{vendorContact.email}</h5>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            ) : null}
                        </div>
                        <div>
                            <Button
                                size="small"
                                color="warning"
                                className="col-2"
                                style={{ marginTop: "1em" }}
                                onClick={() => navigate("/additionalVendorContactInfoPage")}
                            >
                                Back to Contacts
                            </Button>
                        </div>
                        <div>
                            <h2
                                style={{
                                    marginTop: "1em",
                                    width: "35%",
                                }}
                            >
                                Add POs to Shipment:
                            </h2>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Input
                                    style={{
                                        marginTop: "0.5em",
                                        marginBottom: "0.5em",
                                        width: "35%",
                                        marginRight: "1em",
                                    }}
                                    maxLength={15}
                                    placeholder="Enter PO number"
                                    value={poNumber}
                                    onChange={(e) => setPoNumber(e.target.value)}
                                />
                                <Button size="small" color="info" className="col-2" onClick={() => validateShipmentPo()} disabled={poNumber === ""}>
                                    Add to Shipment
                                </Button>
                                <Button
                                    size="small"
                                    color="success"
                                    className="col-2"
                                    style={{
                                        marginLeft: "0.5em",
                                    }}
                                    disabled={shipmentPos.length === 0}
                                    onClick={() => updateShipment()}
                                >
                                    Continue
                                </Button>
                            </div>
                        </div>
                        <div className="col-6" style={{ margin: "auto" }}>
                            {shipmentPos.length > 0 ? (
                                <>
                                    <Table
                                        style={{
                                            marginTop: "1em",
                                        }}
                                    >
                                        <thead>
                                            <tr>
                                                <th className={styleDarkMode}>PO Number</th>
                                                <th className={styleDarkMode}>Manual PO</th>
                                                <th className={styleDarkMode}>Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {shipmentPos?.map((shipmentPo) => (
                                                <tr key={uuidv4()}>
                                                    <td className={styleDarkMode}>{shipmentPo.po !== 0 ? <> {shipmentPo.po} </> : <> N/A </>}</td>
                                                    <td className={styleDarkMode}>{shipmentPo.manualPo !== "" ? <> {shipmentPo.manualPo} </> : <> N/A </>}</td>
                                                    <td className={styleDarkMode}>
                                                        {shipmentPo.value && shipmentPo.value > 0 ? <> ${shipmentPo.value.toFixed(2)} </> : <> N/A </>}
                                                    </td>
                                                    <td style={{ border: "none" }}>
                                                        <Button
                                                            size="small"
                                                            style={{
                                                                backgroundColor: "#a12",
                                                                float: "right",
                                                            }}
                                                            onClick={() => removeShipmentPo(shipmentPo.id)}
                                                        >
                                                            Remove
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </>
            )}
            {modalVisibility ? (
                <div className="modal">
                    <div className="modal-content">
                        {earlyShip ? (
                            <>
                                <h1 style={{ color: "dodgerblue" }}>
                                    <strong>Early Ship</strong>
                                </h1>
                                <h2 className={styleDarkModeOnWhite}>
                                    This PO is not yet due to ship. To continue, please contact a Scheels associate to approve the shipment.
                                </h2>
                                <h2 className={styleDarkModeOnWhite}>If approved, enter the associate's name and location. Thank you.</h2>
                            </>
                        ) : (
                            <>
                                <h1 style={{ color: "#a12" }}>
                                    <strong>Past Cancel</strong>
                                </h1>
                                <h2 className={styleDarkModeOnWhite}>
                                    This PO is past the cancel date. To continue, please contact a Scheels associate to approve the shipment.
                                </h2>
                                <h2 className={styleDarkModeOnWhite}>If approved, enter the associate's name and location. Thank you.</h2>
                            </>
                        )}
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "30%",
                                justifyContent: "center",
                            }}
                        >
                            <Input
                                style={{
                                    marginTop: "0.5em",
                                    marginBottom: "0.5em",
                                    width: "100%",
                                }}
                                placeholder="Enter associate's name"
                                value={scheelsAssociate}
                                onChange={(e) => setScheelsAssociate(e.target.value)}
                            />
                            <Input
                                style={{
                                    marginTop: "0.5em",
                                    marginBottom: "0.5em",
                                    width: "100%",
                                }}
                                placeholder="Enter store location"
                                value={storeLocation}
                                onChange={(e) => setStoreLocation(e.target.value)}
                            />
                            <div style={{ display: "flex", justifyContent: "center", marginTop: "0.5em" }}>
                                <Button color="danger" onClick={() => toggleModalVisibility()}>
                                    Cancel
                                </Button>
                                <div style={{ width: "0.5em" }} />
                                <Button
                                    color="success"
                                    style={{ marginLeft: "0.5em" }}
                                    onClick={() => submitOverride()}
                                    disabled={storeLocation === "" || scheelsAssociate === ""}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
            {manualPoModalVisibility ? (
                <div className="modal">
                    <div className="modal-content">
                        <>
                            <h1 style={{ color: "deeppink" }}>
                                <strong>PO Not Found</strong>
                            </h1>
                            <h2 className={styleDarkModeOnWhite}>The PO provided was not found.</h2>
                            <h2 className={styleDarkModeOnWhite}>Please verify the PO Number entered:</h2>
                            <h2 className={styleDarkModeOnWhite}>{overridePoNumber}</h2>
                            <h2 className={styleDarkModeOnWhite}>If this is correct, please enter the value of the PO shipping:</h2>

                            <Input
                                value={value}
                                onChange={(e) => setValue(e.target.valueAsNumber)}
                                placeholder="please enter value"
                                type="number"
                                min="0"
                                step="0.01"
                                style={{ width: "200px" }}
                            ></Input>
                        </>

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "30%",
                                justifyContent: "center",
                            }}
                        >
                            <div style={{ display: "flex", justifyContent: "center", marginTop: "0.5em" }}>
                                <Button color="danger" onClick={() => cancelManualPo()}>
                                    Cancel
                                </Button>
                                <div style={{ width: "0.5em" }} />
                                <Button color="success" style={{ marginLeft: "0.5em" }} disabled={!value || value <= 0} onClick={() => addManualPo()}>
                                    Continue
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
            <div className="go-back-btn">
                <Button onClick={() => GoBack()}>Go Back</Button>
            </div>
        </>
    );
}

export default PoValidationPage;
