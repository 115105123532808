import axios from "axios";
import { put, takeEvery } from "redux-saga/effects";
import Pallet from "../../../models/Pallet";
import { IS_LOADING } from "../../constants/loadingStatusConstants/loadingStatus.constants";
import { ERROR_NOTIFICATION } from "../../constants/notificationConstants/notification.constants";
import { ADD_SHIPMENT_PALLET, DELETE_ALL_SHIPMENT_PALLETS, DELETE_SHIPMENT_PALLET, FETCH_SHIPMENT_PALLETS, SET_SHIPMENT_PALLETS } from "../../constants/palletConstants/pallet.Constants";
import { ResponseStatus } from "../_rootSaga/ResponseStatus";

function* addShipmentPallet(action: any) {
    yield put({ type: IS_LOADING, payload: true });
    try {
        const inPallet: Pallet = action.payload.newPallet;
        const shipmentId: number = action.payload.shipmentId;
        const request: string = `${process.env.REACT_APP_API_URL}/Shipment/${shipmentId}/Pallet`;
        yield axios.post(request, inPallet);
        yield put({ type: FETCH_SHIPMENT_PALLETS, payload: shipmentId });
    } catch (error) {
        yield put({ type: ERROR_NOTIFICATION, payload: "Failed to add pallet to shipment." });
    }
    yield put({ type: IS_LOADING, payload: false });
}

function* fetchAllPalletsByShipmentId(action: any) {
    yield put({ type: IS_LOADING, payload: true });
    try {
        let shipmentId: string = action.payload;
        const request: string = `${process.env.REACT_APP_API_URL}/Shipment/${shipmentId}/Pallet`;
        const response: ResponseStatus<Pallet[]> = yield axios.get(request);
        const shipmentPallets: Pallet[] = response.data;
        yield put({ type: SET_SHIPMENT_PALLETS, payload: shipmentPallets });
    } catch (error) {
        yield put({ type: ERROR_NOTIFICATION, payload: `Failed to fetch all pallets.` });
    }
    yield put({ type: IS_LOADING, payload: false });
}

function* deletePalletByPalletId(action: any) {
    yield put({ type: IS_LOADING, payload: true });
    try {
        let palletId: number = action.payload.palletId;
        let shipmentId: number = action.payload.shipmentId;
        let request: string = `${process.env.REACT_APP_API_URL}/Shipment/${shipmentId}/Pallet/${palletId}`;
        yield axios.delete(request);
        yield put({ type: FETCH_SHIPMENT_PALLETS, payload: shipmentId });
    } catch (error) {
        yield put({ type: ERROR_NOTIFICATION, payload: `Failed to remove pallet.` });
    }
    yield put({ type: IS_LOADING, payload: false });
}

function* deleteAllShipmentPallets(action: any) {
    yield put({ type: IS_LOADING, payload: true });
    try {
        let shipmentId: number = action.payload;
        const request: string = `${process.env.REACT_APP_API_URL}/Shipment/${shipmentId}/Pallet`;
        yield axios.delete(request);
        yield put({ type: FETCH_SHIPMENT_PALLETS, payload: shipmentId });
    } catch (error) {
        yield put({ type: ERROR_NOTIFICATION, payload: `Failed to remove all pallets.` });
    }
    yield put({ type: IS_LOADING, payload: false });
}

function* palletSaga() {
    yield takeEvery(ADD_SHIPMENT_PALLET, addShipmentPallet);
    yield takeEvery(FETCH_SHIPMENT_PALLETS, fetchAllPalletsByShipmentId);
    yield takeEvery(DELETE_SHIPMENT_PALLET, deletePalletByPalletId);
    yield takeEvery(DELETE_ALL_SHIPMENT_PALLETS, deleteAllShipmentPallets);
}

export default palletSaga;
