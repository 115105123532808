import axios from "axios";
import { put, takeEvery } from "redux-saga/effects";
import AdditionalVendorContactEmail from "../../../models/AdditionalVendorContactInfo";
import {
    ADD_VENDOR_CONTACT,
    FETCH_VENDOR_CONTACTS,
    REMOVE_ALL_VENDOR_CONTACTS,
    REMOVE_VENDOR_CONTACT,
} from "../../constants/additionalVendorContactInfoConstants/AdditionalVendorContactInfo.Constants";
import { IS_LOADING } from "../../constants/loadingStatusConstants/loadingStatus.constants";
import { ERROR_NOTIFICATION } from "../../constants/notificationConstants/notification.constants";
import { additionalVendorContactInfoSet } from "../../reducers/additionalVendorContactInfoReducer/AdditionalVendorContactInfo.Reducer";
import { ResponseStatus } from "../_rootSaga/ResponseStatus";

function* fetchAdditionalVendorContacts(action: any) {
    yield put({ type: IS_LOADING, payload: true });
    try {
        const shipmentId: string = action.payload;
        const request: string = `${process.env.REACT_APP_API_URL}/shipment/${shipmentId}/AdditionalVendorContact`;
        const response: ResponseStatus<any> = yield axios.get(request);
        const vendorContacts: AdditionalVendorContactEmail[] = response.data;
        yield put(additionalVendorContactInfoSet(vendorContacts));
    } catch (error) {
        // const inReview: string = sessionStorage.getItem('inReview') || '';
        // if (inReview === 'true')
        yield put({ type: ERROR_NOTIFICATION, payload: "Failed to fetch additional contacts." });
    } finally {
        yield put({ type: IS_LOADING, payload: false });
    }
}

function* createAdditionalVendorContact(action: any) {
    yield put({ type: IS_LOADING, payload: true });
    try {
        const shipmentId: string = action.payload.shipmentId;
        const additionalVendorContact: AdditionalVendorContactEmail = action.payload;
        const request: string = `${process.env.REACT_APP_API_URL}/shipment/${shipmentId}/AdditionalVendorContact`;
        yield axios.post(request, additionalVendorContact);
        yield put({ type: FETCH_VENDOR_CONTACTS, payload: shipmentId });
    } catch (error) {
        yield put({ type: ERROR_NOTIFICATION, payload: "Failed to add additional contact." });
    }
    yield put({ type: IS_LOADING, payload: false });
}

function* removeAdditionalVendorContact(action: any) {
    yield put({ type: IS_LOADING, payload: true });
    try {
        const shipmentId: string = action.payload.shipmentId;
        const vendorContactId: number = action.payload.contactId;
        const request: string = `${process.env.REACT_APP_API_URL}/shipment/${shipmentId}/AdditionalVendorContact/${vendorContactId}`;
        yield axios.delete(request);
        yield put({ type: FETCH_VENDOR_CONTACTS, payload: shipmentId });
    } catch (error) {
        yield put({ type: ERROR_NOTIFICATION, payload: "Failed to remove additional contact." });
    } finally {
        yield put({ type: IS_LOADING, payload: false });
    }
}

function* removeAllAdditionalVendorContact(action: any) {
    yield put({ type: IS_LOADING, payload: true });
    try {
        const shipmentId: string = action.payload.shipmentId;
        const request: string = `${process.env.REACT_APP_API_URL}/shipment/${shipmentId}/AdditionalVendorContact`;
        yield axios.delete(request);
        yield put({ type: FETCH_VENDOR_CONTACTS, payload: shipmentId });
    } catch (error) {
        yield put({ type: ERROR_NOTIFICATION, payload: "Failed to remove all additional contacts." });
    } finally {
        yield put({ type: IS_LOADING, payload: false });
    }
}

function* additionalVendorContactInfoSaga() {
    yield takeEvery(FETCH_VENDOR_CONTACTS, fetchAdditionalVendorContacts);
    yield takeEvery(ADD_VENDOR_CONTACT, createAdditionalVendorContact);
    yield takeEvery(REMOVE_VENDOR_CONTACT, removeAdditionalVendorContact);
    yield takeEvery(REMOVE_ALL_VENDOR_CONTACTS, removeAllAdditionalVendorContact);
}

export default additionalVendorContactInfoSaga;
