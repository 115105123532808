import { CLEAR_ALL } from "../../constants/reviewConstants/Review.Constants";
import { CLEAR_DESTINATION_ZIP_INFO, SET_DESTINATION_ZIP_INFO } from "../../constants/zipLookupConstants/ZipLookup.Constants";
import { CLEAR_FOR_DUPLICATE } from "../../constants/shipmentConstants/Shipment.constants";

const destinationZipLookupReducer = (state = {}, action: any) => {
    switch (action.type) {
        case SET_DESTINATION_ZIP_INFO:
            return action.payload;
        case CLEAR_DESTINATION_ZIP_INFO:
            return {};
        case CLEAR_ALL:
            return {};
        case CLEAR_FOR_DUPLICATE:
            return {};
        default:
            return state;
    }
};

export default destinationZipLookupReducer;
