import App from "./pages/App";
import ReactDOM from "react-dom/client";
import rootReducer from "./redux/reducers/_rootReducer/_Root.Reducer";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./redux/sagas/_rootSaga/Root.saga";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import "redux-persist";
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import { PersistGate } from "redux-persist/es/integration/react";
import storage from "redux-persist/lib/storage";
import { configureStore } from "@reduxjs/toolkit";
import { GoogleOAuthProvider } from "@react-oauth/google";
import * as Sentry from "@sentry/react";

const sagaMiddleware = createSagaMiddleware();
const middlewareList = [sagaMiddleware];

Sentry.init({
    dsn: "https://71cdaa03c3f04bdc8c5f4b19d5c9f6bf@o1113529.ingest.sentry.io/4504877444562944",
    // integrations: [new BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
});

const persistConfig = {
    key: "root",
    storage,
    version: 1,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
    reducer: persistedReducer,
    devtools: true,
    middleware: middlewareList,
});
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <GoogleOAuthProvider clientId="3653309562-qje2ujln7p89mrgg11n6h4uhq0oteuo5.apps.googleusercontent.com">
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <Router>
                    <App />
                </Router>
            </PersistGate>
        </Provider>
    </GoogleOAuthProvider>
);
